// Global QV variables
$modal-quickview-padding: rem-calc(50 15 25) !default;
$modal-quickview-stepper-margin: rem-calc(0 0 10) !default;
$modal-quickview-discover-link-margin: rem-calc(0 0 10) !default;
$modal-quickview-price-info-padding: rem-calc(0 0 0 10) !default;
$modal-quickview-price-info-margin: rem-calc(0 0 0 10) !default;
$modal-quickview-discover-button-font-size: rem-calc(14) !default;
$modal-quickview-discover-button-height--small: rem-calc(40) !default;
$modal-quickview-controls-padding: rem-calc(0 0 0 55) !default;
$modal-quickview-main-info-padding: rem-calc(0 0 0 55) !default;
$modal-quickview-max-width--large: rem-calc(1170) !default;
$modal-quickview-product-tile-info-separator-color: color(border) !default;
$modal-quickview-error-order--medium-down: -1 !default;
$modal-quickview-rating-margin--large: rem-calc(0 12 0 0) !default;
$modal-quickview-main-recommendation-message-margin: rem-calc(30 0) !default;
$modal-quickview-main-recommendation-message-margin--large: rem-calc(60 0 0) !default;
$modal-quickview-main-name-text-transform: none !default;
$modal-quickview-main-more-details-font: null !default;
$modal-quickview-main-more-details-color: $anchor-color !default;
$modal-quickview-main-more-details-text-transform: none !default;
$modal-quickview-main-more-details-display: none !default;
$modal-quickview-main-more-details-display--large: inline !default;
$modal-quickview-main-more-details-margin: null !default;
$modal-quickview-main-more-details-margin--large: null !default;
$modal-quickview-main-more-details-letter-spacing: null !default;
$modal-quickview-main-image-order: 1 !default;
$modal-quickview-main-info-order: 2 !default;
$modal-quickview-main-controls-order: 3 !default;
$modal-quickview-main-where-to-buy-margin: 0 !default;
$modal-quickview-main-where-to-buy-margin--large: rem-calc(5 0 20 0) !default;
$modal-quickview-main-info-text-align: center !default;
// Bundle
$modal-quickview-product-bundle-title-qty-margin: rem-calc(0 0 0 10) !default;
$modal-quickview-product-bundle-title-qty-before-content: "(" !default;
$modal-quickview-product-bundle-title-qty-after-content: ")" !default;
$modal-quickview-product-bundle-title-border: none !default;
$modal-quickview-product-bundle-title-margin: 0 !default;
$modal-quickview-product-bundle-title-padding: rem-calc(0 0 20) !default;
$modal-quickview-product-bundle-title-after-display: none !default;
$modal-quickview-product-bundle-content-max-height: none !default;
$modal-quickview-product-bundle-item-last-child-border-bottom: none !default;
$modal-quickview-product-bundle-border-top: 1px solid color(border) !default;
$modal-quickview-product-bundle-padding: rem-calc(20 0 0) !default;
$modal-quickview-product-bundle-margin: rem-calc(20 0 0) !default;
// PDPv2
$modal-quickview-product-main-v2-image-width--large: rem-calc(550) !default;
$modal-quickview-product-main-v2-aside-width--large: minmax(0, 1fr) !default;
$modal-quickview-product-main-v2-image-padding--large: rem-calc(0 90 0 0) !default;
$modal-quickview-product-main-v2-more-details-font: #{rem-calc(14)} / 1.25 $font-primary !default;
$modal-quickview-product-main-v2-more-details-font--large: #{rem-calc(14)} / 1.25 $font-primary !default;
$modal-quickview-product-main-v2-more-details-display: table !default;
$modal-quickview-product-main-v2-more-details-display--large: table !default;
$modal-quickview-product-main-v2-more-details-margin: auto !default;
$modal-quickview-product-main-v2-more-details-margin--large: auto !default;
$modal-quickview-product-main-v2-more-details-letter-spacing: null !default;
$modal-quickview-product-main-v2-actions-margin: rem-calc(0 0 10) !default;
$modal-quickview-product-main-v2-actions-margin--large: rem-calc(0 0 10) !default;
$modal-quickview-product-main-v2-more-details-text-transform: null !default;
$modal-quickview-product-main-v2-more-details-text-align: null !default;

@mixin lora-components-modal-quickview {
    .c-modal.m-quickview {
        .c-modal__wrapper {
            width: 100vw;

            @include breakpoint(medium down) {
                padding: $modal-quickview-padding;
            }

            @include breakpoint(large) {
                max-width: $modal-quickview-max-width--large;
            }
        }

        .c-product-thumbs .c-carousel__inner {
            @include breakpoint(medium down) {
                overflow: hidden;
            }
        }

        .c-product-main {
            border-bottom: none;

            &::before {
                display: none;
            }
        }

        .c-product-main,
        .c-product-main__inner,
        .c-carousel.m-style-product-variations.m-alt {
            padding: 0;
        }

        .c-product-main__inner {
            @include breakpoint(medium down) {
                display: flex;
                flex-flow: column;
                overflow: visible;

                .c-product-main__image {
                    order: $modal-quickview-main-image-order;
                }

                .c-product-main__info {
                    order: $modal-quickview-main-info-order;
                    padding: 0;
                    text-align: $modal-quickview-main-info-text-align;
                }

                .c-product-main__controls {
                    order: $modal-quickview-main-controls-order;
                }
            }
        }

        .c-product-main__info {
            @include breakpoint(large) {
                padding: $modal-quickview-main-info-padding;
            }
        }

        .c-product-main__name {
            text-transform: $modal-quickview-main-name-text-transform;
        }

        .c-product-main__controls {
            @include breakpoint(large) {
                padding: $modal-quickview-controls-padding;
            }
        }

        .c-rating {
            @include breakpoint(large) {
                margin: $modal-quickview-rating-margin--large;
            }
        }

        // Remove write review link, the selector name is managed by the third party on which we do not have naming control
        /* stylelint-disable */
        [data-bv-show="rating_summary"] .bv_button_component_container #WAR {
            display: none !important;
        }
        /* stylelint-enable */

        .c-product-main__more-details {
            display: $modal-quickview-main-more-details-display;
            font: $modal-quickview-main-more-details-font;
            color: $modal-quickview-main-more-details-color;
            text-transform: $modal-quickview-main-more-details-text-transform;
            margin: $modal-quickview-main-more-details-margin;
            letter-spacing: $modal-quickview-main-more-details-letter-spacing;

            &:hover {
                text-decoration: none;
            }

            @include breakpoint(large) {
                display: $modal-quickview-main-more-details-display--large;
                margin: $modal-quickview-main-more-details-margin--large;
            }
        }

        .c-product-detail-image__zoom {
            display: none;
        }

        .c-product-main__actions {
            @include breakpoint(medium down) {
                display: block;
                position: static;

                .c-stepper-input {
                    margin: $modal-quickview-stepper-margin;
                }

                .c-product-add-bag__price {
                    display: none;
                }
            }
        }

        .c-product-main__recommendation-message {
            margin: $modal-quickview-main-recommendation-message-margin;

            @include breakpoint(large) {
                margin: $modal-quickview-main-recommendation-message-margin--large;
            }
        }

        .c-product-main__discover-link {
            @include hide-for(large);

            margin: $modal-quickview-discover-link-margin;

            .c-button {
                font-size: $modal-quickview-discover-button-font-size;
                width: 100%;

                @include breakpoint(small only) {
                    height: $modal-quickview-discover-button-height--small;
                }
            }
        }

        .c-product-main__add-bag {
            display: flex;
            flex-flow: column;
        }

        .c-product-main__error {
            @include breakpoint(medium down) {
                bottom: 0;
                order: $modal-quickview-error-order--medium-down;
                position: relative;
            }
        }

        .c-product-main__where-to-buy {
            margin: $modal-quickview-main-where-to-buy-margin;

            @include breakpoint(large) {
                margin: $modal-quickview-main-where-to-buy-margin--large;
            }
        }

        .c-product-price__info {
            @include breakpoint(medium down) {
                border-#{$global-left}: 1px solid $modal-quickview-product-tile-info-separator-color;
                margin: $modal-quickview-price-info-margin;
                padding: $modal-quickview-price-info-padding;
                text-align: $global-left;

                &:first-child {
                    border: none;
                    text-align: $global-right;
                }
            }
        }

        // Bundle
        .c-product-bundle__title-qty {
            margin: $modal-quickview-product-bundle-title-qty-margin;

            &::before {
                content: $modal-quickview-product-bundle-title-qty-before-content;
            }

            &::after {
                content: $modal-quickview-product-bundle-title-qty-after-content;
            }
        }

        .c-product-bundle {
            border-top: $modal-quickview-product-bundle-border-top;
            margin: $modal-quickview-product-bundle-margin;
            padding: $modal-quickview-product-bundle-padding;
        }

        .c-product-bundle__title {
            border: $modal-quickview-product-bundle-title-border;
            margin: $modal-quickview-product-bundle-title-margin;
            padding: $modal-quickview-product-bundle-title-padding;

            & > .c-accordion__icon::after {
                display: $modal-quickview-product-bundle-title-after-display;
            }
        }

        .c-product-bundle__content {
            @include lora-animation-expanded;

            max-height: $modal-quickview-product-bundle-content-max-height;
        }

        .c-product-bundle__item:last-child {
            border-bottom: $modal-quickview-product-bundle-item-last-child-border-bottom;
        }

        .c-product-main.m-v2 {
            .c-product-main__inner {
                @include breakpoint(large) {
                    /* stylelint-disable max-nesting-depth */
                    grid-template-columns: $modal-quickview-product-main-v2-image-width--large $modal-quickview-product-main-v2-aside-width--large;
                    /* stylelint-enable max-nesting-depth */
                }
            }

            .c-product-main__image {
                @include breakpoint(large) {
                    padding: $modal-quickview-product-main-v2-image-padding--large;
                }
            }

            .c-product-main__more-details {
                display: $modal-quickview-product-main-v2-more-details-display;
                font: $modal-quickview-product-main-v2-more-details-font;
                margin: $modal-quickview-product-main-v2-more-details-margin;
                text-transform: $modal-quickview-product-main-v2-more-details-text-transform;
                text-align: $modal-quickview-product-main-v2-more-details-text-align;
                letter-spacing: $modal-quickview-product-main-v2-more-details-letter-spacing;

                @include breakpoint(large) {
                    display: $modal-quickview-product-main-v2-more-details-display--large;
                    font: $modal-quickview-product-main-v2-more-details-font--large;
                    margin: $modal-quickview-product-main-v2-more-details-margin--large;
                }
            }

            .c-product-main__actions {
                margin: $modal-quickview-product-main-v2-actions-margin;

                @include breakpoint(large) {
                    margin: $modal-quickview-product-main-v2-actions-margin--large;
                }

                /* stylelint-disable selector-max-compound-selectors */
                &.m-no-margin {
                    margin: 0 !important; /* stylelint-disable-line */

                    // stylelint-disable max-nesting-depth
                    .c-button {
                        margin: $modal-quickview-product-main-v2-actions-margin;

                        @include breakpoint(large) {
                            margin: $modal-quickview-product-main-v2-actions-margin--large;
                        }
                    }
                    // stylelint-enable max-nesting-depth
                }
                /* stylelint-enable selector-max-compound-selectors */
            }
        }
    }

    .c-modal.m-gift-certificate {
        .c-gift-certificate__terms {
            display: none;
        }
    }
}
