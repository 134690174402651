
                            $is-app_ng_checkout-enabled: true;
                            @import "../icon";
@import "../../05-components/form/check-field";

$ngcheckout-selection-margin: rem-calc(0 0 10) !default;
// Header
$ngcheckout-selection-header-padding: rem-calc(15) !default;
$ngcheckout-selection-header-background: color(light) !default;
$ngcheckout-selection-header-border: 1px solid color(border) !default;
$ngcheckout-selection-header-border-radius: null !default;
$ngcheckout-selection-active-header-border: 2px solid color(info) !default;
$ngcheckout-selection-active-header-border-radius: null !default;
$ngcheckout-selection-active-header-background: color(primary-background) !default;
$ngcheckout-selection-header-icon-offset: rem-calc(40) !default;
$ngcheckout-selection-header-completed-icon-offset: rem-calc(30) !default;
$ngcheckout-selection-header-completed-border: none !default;
$ngcheckout-selection-header-completed-padding: rem-calc(0 15) !default;
$ngcheckout-selection-header-completed-margin: rem-calc(15 0) !default;
$ngcheckout-selection-header-completed-icon-top: 0 !default;
$ngcheckout-selection-header-justify-content: space-between !default;
$ngcheckout-selection-header-margin: null !default;
$ngcheckout-selection-header-margin--large: null !default;
// Title
$ngcheckout-selection-title-font: 700 #{rem-calc(14)} / 1.4 $font-primary !default;
$ngcheckout-selection-title-margin: rem-calc(0 10 0 0) !default;
$ngcheckout-selection-title-color: null !default;
// Title fullwidth
$ngcheckout-selection-title-fullwidth-icon-max-width: 30% !default;
// Title icon
$ngcheckout-selection-title-icon-max-height: rem-calc(20) !default;
$ngcheckout-selection-title-icon-small-max-height: rem-calc(10) !default;
// Title label
$ngcheckout-selection-title-label-margin: rem-calc(0 0 0 10) !default;
$ngcheckout-selection-title-label-flex: 1 1 auto !default;
// Description
$ngcheckout-selection-description-margin: rem-calc(5 0 0) !default;
$ngcheckout-selection-description-font: null !default;
$ngcheckout-selection-description-color: null !default;
$ngcheckout-selection-description-letter-spacing: null !default;
// Payment method description
$ngcheckout-selection-description-payment-font: 300 #{rem-calc(12)} / 1.5 $font-primary !default;
$ngcheckout-selection-description-payment-margin: rem-calc(10 0 0) !default;
// Content, active
$ngcheckout-selection-active-content-background: color(primary-background) !default;
$ngcheckout-selection-active-content-padding: rem-calc(20) !default;
$ngcheckout-selection-active-content-border: null !default;
$ngcheckout-selection-active-content-border-radius: null !default;
// Icon
$ngcheckout-selection-icon-list: (home, store, pin, gmail) !default;
$ngcheckout-selection-icon-color: color(text) !default;
$ngcheckout-selection-icon-size: rem-calc(15) !default;
$ngcheckout-selection-icon-top: rem-calc(15) !default;
$ngcheckout-selection-icon-offset: rem-calc(15) !default;
$ngcheckout-selection-icon-is-mask: true !default;
// Actions
$ngcheckout-selection-actions-font: 700 #{rem-calc(14)} / 1 $font-primary !default;
$ngcheckout-selection-actions-margin: rem-calc(0 0 10) !default;
// Price
$ngcheckout-selection-price-old-text-decoration: line-through !default;
$ngcheckout-selection-price-old-color: color(disabled) !default;
// Disabled state
$ngcheckout-selection-header-disabled-opacity: 0.5 !default;
// Radio section
$ngcheckout-selection-header-radio-offset: rem-calc(40) !default;
$ngcheckout-selection-header-radio-icon-offset: rem-calc(12) !default;
$ngcheckout-selection-header-radio-icon-margin: 0 !default;

@mixin lora-components-ngcheckout-selection {
    .c-ngcheckout-selection {
        margin: $ngcheckout-selection-margin;

        &.m-active {
            & > .c-ngcheckout-selection__header {
                border: $ngcheckout-selection-active-header-border;
                background: $ngcheckout-selection-active-header-background;
                border-radius: $ngcheckout-selection-active-header-border-radius;

                &.m-radio {
                    @include lora-helper-check-field-radio-checked-state;
                }

                &.m-radio:focus {
                    @include lora-helper-check-field-radio-checked-focused-state;
                }
            }

            & > .c-ngcheckout-selection__content {
                @include breakpoint(large) {
                    display: block;
                    background: $ngcheckout-selection-active-content-background;
                    padding: $ngcheckout-selection-active-content-padding;
                    border: $ngcheckout-selection-active-content-border;
                    border-radius: $ngcheckout-selection-active-content-border-radius;
                }
            }

            .c-ngcheckout-selection__form {
                display: block;
            }
        }
    }

    .c-ngcheckout-selection__content {
        display: none;
    }

    .c-ngcheckout-selection__header {
        display: flex;
        position: relative;
        justify-content: $ngcheckout-selection-header-justify-content;
        padding: $ngcheckout-selection-header-padding;
        margin: $ngcheckout-selection-header-margin;
        border: $ngcheckout-selection-header-border;
        border-radius: $ngcheckout-selection-header-border-radius;
        background: $ngcheckout-selection-header-background;
        cursor: pointer;

        @include breakpoint(large) {
            margin: $ngcheckout-selection-header-margin--large;
        }

        &.m-icon:not(.m-radio) {
            padding-#{$global-left}: $ngcheckout-selection-header-icon-offset;
        }

        &.m-radio {
            @include lora-helper-check-field-radio-unchecked-state;

            padding-#{$global-left}: $ngcheckout-selection-header-radio-offset;

            &::before {
                #{$global-left}: $ngcheckout-selection-header-radio-icon-offset;
                margin: $ngcheckout-selection-header-radio-icon-margin;
            }

            &:focus {
                @include lora-helper-check-field-radio-unchecked-focused-state;
            }
        }

        &.m-completed {
            border: $ngcheckout-selection-header-completed-border;
            padding: $ngcheckout-selection-header-completed-padding;
            margin: $ngcheckout-selection-header-completed-margin;
            cursor: initial;

            &.m-icon:not(.m-radio) {
                padding-#{$global-left}: $ngcheckout-selection-header-completed-icon-offset;
            }

            .c-ngcheckout-selection__icon {
                #{$global-left}: 0;
                top: $ngcheckout-selection-header-completed-icon-top;
            }
        }

        &.m-disabled {
            opacity: $ngcheckout-selection-header-disabled-opacity;
            pointer-events: none;
        }
    }

    .c-ngcheckout-selection__header-inner {
        flex-grow: 1;
    }

    .c-ngcheckout-selection__title {
        font: $ngcheckout-selection-title-font;
        margin: $ngcheckout-selection-title-margin;
        color: $ngcheckout-selection-title-color;
        max-width: 100%;

        &.m-fullwidth {
            width: 100%;
            margin-inline: 0;

            .c-ngcheckout-selection__title-icon {
                max-width: $ngcheckout-selection-title-fullwidth-icon-max-width;
            }
        }
    }

    .c-ngcheckout-selection__title-icon {
        font-size: 0;

        img {
            max-height: $ngcheckout-selection-title-icon-max-height;
        }

        &.m-small {
            img {
                max-height: $ngcheckout-selection-title-icon-small-max-height;
            }
        }
    }

    .c-ngcheckout-selection__title-label {
        @include text-truncate;

        margin: $ngcheckout-selection-title-label-margin;
        flex: $ngcheckout-selection-title-label-flex;
    }

    .c-ngcheckout-selection__icon {
        @include lora-helper-icon-base(
            $color: $ngcheckout-selection-icon-color
        );

        position: absolute;
        top: $ngcheckout-selection-icon-top;
        #{$global-left}: $ngcheckout-selection-icon-offset;
    }

    @each $icon in $ngcheckout-selection-icon-list {
        .c-ngcheckout-selection__icon.m-#{$icon}:not(.m-radio) {
            @include lora-helper-icon-svg(
                $icon: "#{$icon}",
                $size: $ngcheckout-selection-icon-size,
                $is-mask: $ngcheckout-selection-icon-is-mask
            );
        }
    }

    .c-ngcheckout-selection__description {
        margin: $ngcheckout-selection-description-margin;
        font: $ngcheckout-selection-description-font;
        color: $ngcheckout-selection-description-color;
        letter-spacing: $ngcheckout-selection-description-letter-spacing;

        &.m-payment {
            font: $ngcheckout-selection-description-payment-font;
            margin: $ngcheckout-selection-description-payment-margin;
        }
    }

    .c-ngcheckout-selection__actions {
        font: $ngcheckout-selection-actions-font;
        margin: $ngcheckout-selection-actions-margin;
    }

    .c-ngcheckout-selection__form {
        display: none;
    }

    .c-ngcheckout-selection__price {
        &.m-old {
            text-decoration: $ngcheckout-selection-price-old-text-decoration;
            color: $ngcheckout-selection-price-old-color;
        }
    }
}

                        