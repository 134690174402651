$account-store-suggestion-hover-color: color(primary-active);
$account-store-suggestion-cursor: pointer;
$account-store-suggestion-hover-font-weight: bold;
$account-store-wrapper-padding: rem-calc(5 20);
$account-store-wrapper-max-height: rem-calc(100);
$account-store-wrapper-width: 100%;

@mixin apaclayer-components-account-store {
    .c-account-store__wrapper {
        padding: $account-store-wrapper-padding;
        max-height: $account-store-wrapper-max-height;
        width: $account-store-wrapper-width;
    }

    .c-account-store__suggestion {
        cursor: $account-store-suggestion-cursor;

        &:hover {
            color: $account-store-suggestion-hover-color;
            font-weight: $account-store-suggestion-hover-font-weight;
        }
    }
}
