$storelocator-store-position: relative !default;
$storelocator-store-padding: rem-calc(15 20 15 40) !default;
$storelocator-store-padding--large: null !default;
$storelocator-store-margin: 0 !default;
$storelocator-store-margin--large: null !default;
$storelocator-store-border: solid color(border) !default;
$storelocator-store-border-radius: null !default;
$storelocator-store-border-width: 1px 0 0 0 !default;
$storelocator-store-box-shadow: null !default;
$storelocator-store-address-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$storelocator-store-address-font--large: null !default;
$storelocator-store-address-letter-spacing: null !default;
$storelocator-store-address-letter-spacing--large: null !default;
$storelocator-store-address-color: null !default;
$storelocator-store-address-text-transform: null !default;
$storelocator-store-phone-text-font: #{rem-calc(12)} / 1.4 $font-primary !default;
$storelocator-store-phone-text-font--large: null !default;
$storelocator-store-phone-text-decoration: none !default;
$storelocator-store-phone-text-color: null !default;
$storelocator-store-phone-icon: phone !default;
$storelocator-store-phone-icon-color: color(primary) !default;
$storelocator-store-phone-icon-size: rem-calc(15) !default;
$storelocator-store-phone-icon-vertical-align: middle !default;
$storelocator-store-phone-icon-margin: rem-calc(0 8 0 -2) !default;
$storelocator-store-phone-icon-usesvg: true !default;
$storelocator-store-phone-text-letter-spacing: null !default;
$storelocator-store-phone-text-letter-spacing--large: null !default;
$storelocator-store-title-font: 500 #{rem-calc(14)} / 1.5 $font-primary !default;
$storelocator-store-title-text-transform: null !default;
$storelocator-store-title-color: null !default;
$storelocator-store-title-margin: null !default;
$storelocator-store-title-margin--large: null !default;
$storelocator-store-title-word-break: null !default;
$storelocator-store-details-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$storelocator-store-details-letter-spacing: null !default;
$storelocator-store-details-text-transform: null !default;
$storelocator-store-website-text-font: null !default;
$storelocator-store-website-text-font--large: null !default;
$storelocator-store-website-text-color: null !default;
$storelocator-store-website-margin: null !default;
$storelocator-store-website-margin--large: null !default;

$storelocator-store-active-background-color: color(global-background) !default;
$storelocator-store-active-border-width: null !default;

$storelocator-store-marker-label-position--top: rem-calc(4) !default;
$storelocator-store-marker-label-position--left: 0 !default;
$storelocator-store-marker-label-text-align: center !default;
$storelocator-store-marker-label-font: #{rem-calc(12)} / 1.5 $font-primary !default;
$storelocator-store-marker-label-color: #fff !default;

$storelocator-store-marker-position: absolute !default;
$storelocator-store-marker-position--left: rem-calc(10) !default;
$storelocator-store-marker-position--left--large: null !default;
$storelocator-store-marker-position--top: null !default;
$storelocator-store-marker-position--top--large: null !default;
$storelocator-store-marker-icon-height: rem-calc(26) !default;
$storelocator-store-marker-width: 100% !default;
$storelocator-store-marker-margin: null !default;
$storelocator-store-marker-margin--large: null !default;

$storelocator-store-distance-margin: rem-calc(10 0) !default;
$storelocator-store-distance-margin--large: null !default;
$storelocator-store-distance-margin-right: rem-calc(8) !default;
$storelocator-store-distance-color: color(text-secondary) !default;
$storelocator-store-distance-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$storelocator-store-distance-font--large: null !default;
$storelocator-store-distance-letter-spacing: null !default;
$storelocator-store-distance-letter-spacing--large: null !default;

$storelocator-store-icon: pin !default;
$storelocator-store-icon-color: color(primary) !default;
$storelocator-store-icon-height: rem-calc(18) !default;
$storelocator-store-icon-width: rem-calc(12) !default;
$storelocator-store-icon-position--left: rem-calc(10) !default;
$storelocator-store-icon-margin: rem-calc(0 8 0 0) !default;
$storelocator-store-icon-usesvg: true !default;
$storelocator-store-letter-spacing: null !default;
$storelocator-store-word-wrap: null !default;

$storelocator-store-hours-title-icon: chevron-down !default;
$storelocator-store-hours-title-icon-usesvg: true !default;
$storelocator-store-hours-expanded-title-icon: chevron-top !default;
$storelocator-store-hours-expanded-title-icon-usesvg: true !default;
$storelocator-store-hours-title-icon-color: color(text) !default;
$storelocator-store-hours-title-icon-size: rem-calc(10) !default;
$storelocator-store-hours-title-icon-margin: rem-calc(0 0 0 10) !default;
$storelocator-store-hours-content-padding: rem-calc(15 0 0) !default;

@mixin lora-components-storelocator-store {
    .c-storelocator__store {
        padding: $storelocator-store-padding;
        border: $storelocator-store-border;
        border-width: $storelocator-store-border-width;
        border-radius: $storelocator-store-border-radius;
        box-shadow: $storelocator-store-box-shadow;
        position: $storelocator-store-position;
        margin: $storelocator-store-margin;
        letter-spacing: $storelocator-store-letter-spacing;
        word-wrap: $storelocator-store-word-wrap;

        &.m-active,
        &:hover {
            background: $storelocator-store-active-background-color;
            border-width: $storelocator-store-active-border-width;
            cursor: pointer;
        }

        @include breakpoint(large) {
            margin: $storelocator-store-margin--large;
            padding: $storelocator-store-padding--large;
        }

        &.m-active {
            .c-storelocator__store-details {
                display: block;
            }
        }
    }

    .c-storelocator__store-title {
        font: $storelocator-store-title-font;
        text-transform: $storelocator-store-title-text-transform;
        margin: $storelocator-store-title-margin;
        word-break: $storelocator-store-title-word-break;
        color: $storelocator-store-title-color;

        @include breakpoint(large) {
            margin: $storelocator-store-title-margin--large;
        }
    }

    .c-storelocator__store-marker {
        position: $storelocator-store-marker-position;
        #{$global-left}: $storelocator-store-marker-position--left;
        top: $storelocator-store-marker-position--top;
        margin: $storelocator-store-marker-margin;

        @include breakpoint(large) {
            #{$global-left}: $storelocator-store-marker-position--left--large;
            top: $storelocator-store-marker-position--top--large;
            margin: $storelocator-store-marker-margin--large;
        }
    }

    .c-storelocator__store-marker-icon {
        height: $storelocator-store-marker-icon-height;
    }

    .c-storelocator__store-marker-label {
        position: absolute;
        top: $storelocator-store-marker-label-position--top;
        #{$global-left}: $storelocator-store-marker-label-position--left;
        font: $storelocator-store-marker-label-font;
        color: $storelocator-store-marker-label-color;
        width: $storelocator-store-marker-width;
        text-align: $storelocator-store-marker-label-text-align;
    }

    .c-storelocator__store-distance {
        @include text-style(
            $font: (
                small: $storelocator-store-distance-font,
                large: $storelocator-store-distance-font--large
            ),
            $margin: (
                small: $storelocator-store-distance-margin,
                large: $storelocator-store-distance-margin--large
            ),
            $letter-spacing: (
                small: $storelocator-store-distance-letter-spacing,
                large: $storelocator-store-distance-letter-spacing--large
            ),
            $color: (
                small: $storelocator-store-distance-color
            )
        );

        &::before {
            @include svg-icon($storelocator-store-icon, $storelocator-store-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-store-icon-usesvg);

            content: '';
            display: inline-block;
            vertical-align: middle;
            height: $storelocator-store-icon-height;
            width: $storelocator-store-icon-width;
            margin: $storelocator-store-icon-margin;
        }
    }

    .c-storelocator__store-details-link {
        @include show-for(large);

        font: $storelocator-store-details-font;
        letter-spacing: $storelocator-store-details-letter-spacing;
        text-transform: $storelocator-store-details-text-transform;
    }

    .c-storelocator__store-address {
        @include text-style(
            $font: (
                small: $storelocator-store-address-font,
                large: $storelocator-store-address-font--large
            ),
            $letter-spacing: (
                small: $storelocator-store-address-letter-spacing,
                large: $storelocator-store-address-letter-spacing--large
            ),
            $text-transform: (
                small: $storelocator-store-address-text-transform
            ),
            $color: (
                small: $storelocator-store-address-color
            )
        );
    }

    .c-storelocator__store-phone-text {
        @include text-style(
            $font: (
                small: $storelocator-store-phone-text-font,
                large: $storelocator-store-phone-text-font--large
            ),
            $letter-spacing: (
                small: $storelocator-store-phone-text-letter-spacing,
                large: $storelocator-store-phone-text-letter-spacing--large
            ),
            $text-decoration: (
                small: $storelocator-store-phone-text-decoration
            ),
            $color: (
                small: $storelocator-store-phone-text-color
            )
        );

        &::before {
            @include svg-icon($storelocator-store-phone-icon, $storelocator-store-phone-icon-color, contain, center, no-repeat, $usesvg: $storelocator-store-phone-icon-usesvg);

            display: inline-block;
            content: '';
            width: $storelocator-store-phone-icon-size;
            height: $storelocator-store-phone-icon-size;
            vertical-align: $storelocator-store-phone-icon-vertical-align;
            margin: $storelocator-store-phone-icon-margin;
        }
    }

    .c-storelocator__store-website {
        margin: $storelocator-store-website-margin;

        @include breakpoint(large) {
            margin: $storelocator-store-website-margin--large;
        }
    }

    .c-storelocator__store-website-text {
        word-break: break-all;
        font: $storelocator-store-website-text-font;
        color: $storelocator-store-website-text-color;

        @include breakpoint(large) {
            font: $storelocator-store-website-text-font--large;
        }
    }

    .c-storelocator__store-details {
        display: none;
    }

    .c-storelocator__store-hours.m-expanded {
        .c-storelocator__store-hours-container {
            @include lora-animation-expanded;
        }

        .c-storelocator__store-hours-title {
            &::after {
                @include svg-icon($storelocator-store-hours-expanded-title-icon, $storelocator-store-hours-title-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-store-hours-expanded-title-icon-usesvg);
            }
        }
    }

    .c-storelocator__store-hours-title {
        cursor: pointer;

        &::after {
            @include svg-icon($storelocator-store-hours-title-icon, $storelocator-store-hours-title-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-store-hours-title-icon-usesvg);

            content: '';
            display: inline-block;
            vertical-align: middle;
            height: $storelocator-store-hours-title-icon-size;
            width: $storelocator-store-hours-title-icon-size;
            margin: $storelocator-store-hours-title-icon-margin;
        }
    }

    .c-storelocator__store-hours-container {
        @include lora-animation-collapsed;
    }

    .c-storelocator__store-hours-content {
        padding: $storelocator-store-hours-content-padding;
    }
}
