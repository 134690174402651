@import '@root/04-layout/account';

$account-modal-close-button-font-color: #fff;
$account-modal-close-button-bg-color: #000;
$account-modal-close-button-hover-color: #ce0058;
$account-modal-mouse-events: auto;
$account-modal-close-left-position: 0;
$account-modal-close-center-content: 0 auto;
$account-modal-close-content-font-size: rem-calc(12);
$account-modal-close-default-button-image: none;
$account-modal-close-button-radius: rem-calc(5);
$account-modal-close-button-min-width: rem-calc(154);
$account-modal-close-button-padding-space: rem-calc(18 12);
$account-modal-close-button-letter-spacing: rem-calc(0.8);
$account-modal-wrapper-margin-space: 0;
$account-modal-wrapper-height: 17rem;
$account-modal-wrapper-width: 90%;
$account-modal-wrapper-padding: rem-calc(34 15);
 
.m-customized-modal {
    pointer-events: $account-modal-mouse-events;
 
    .c-modal__wrapper {
        @include breakpoint(small only) {
            margin-top: $account-modal-wrapper-margin-space;
            height: $account-modal-wrapper-height;
            width: $account-modal-wrapper-width;
            padding: $account-modal-wrapper-padding;
        }
 
        .c-modal__close {
            left: $account-modal-close-left-position;
            background-color: $account-modal-close-button-bg-color;
            color: $account-modal-close-button-font-color;
            margin: $account-modal-close-center-content;
            display: flex;
            justify-content: center;
            flex-direction: column;
            border-radius: $account-modal-close-button-radius;
            position: relative;
            min-width: $account-modal-close-button-min-width;
            padding: $account-modal-close-button-padding-space;
            transition: background-color 0.25s ease-out;
            letter-spacing: $account-modal-close-button-letter-spacing;
            border: 1px solid transparent;

            &:hover {
                background: $account-modal-close-button-hover-color;
                text-decoration: none;
            }
 
            &::before {
                background-image: $account-modal-close-default-button-image;
                font-size: $account-modal-close-content-font-size;
                content: "Okay";
                align-self: center;
                width: auto;
                height: auto;
            }
        }
    }
}