// item
$account-reviews-item-margin: rem-calc(0 0 20) !default;
$account-reviews-item-padding: rem-calc(0 0 20) !default;
$account-reviews-item-border-bottom: 1px solid color(border) !default;
$account-reviews-item-margin--large: rem-calc(0 0 30) !default;
$account-reviews-item-padding--large: rem-calc(0 0 30) !default;
// image
$account-reviews-image-width: rem-calc(85) !default;
$account-reviews-image-height: rem-calc(85) !default;
$account-reviews-image-margin: rem-calc(0 20 15 0) !default;
$account-reviews-image-width--large: rem-calc(170) !default;
$account-reviews-image-height--large: rem-calc(170) !default;
$account-reviews-image-margin--large: rem-calc(0 30 0 0) !default;
// productnamelink
$account-reviews-productnamelink-font-size: rem-calc(14) !default;
$account-reviews-productnamelink-font-weight: normal !default;
$account-reviews-productnamelink-line-height: 1.6 !default;
$account-reviews-productnamelink-max-lines: 1 !default;
$account-reviews-productnamelink-margin: rem-calc(0 0 7) !default;
$account-reviews-productnamelink-text-transform: null !default;
// productsubtitle
$account-reviews-productsubtitle-color: color(text-secondary) !default;
$account-reviews-productsubtitle-margin: rem-calc(0 0 10) !default;
$account-reviews-productsubtitle-font-size: rem-calc(12) !default;
$account-reviews-productsubtitle-line-height: 1.6 !default;
$account-reviews-productsubtitle-max-lines: 1 !default;
// title
$account-reviews-title-font-size: rem-calc(14) !default;
$account-reviews-title-font-family: $font-primary !default;
$account-reviews-title-font-weight: null !default;
$account-reviews-title-font: $account-reviews-title-font-weight $account-reviews-title-font-size $account-reviews-title-font-family !default;
$account-reviews-title-text-transform: null !default;
$account-reviews-title-margin: rem-calc(0 30 0 0) !default;
// review
$account-reviews-review-text-margin: rem-calc(10 0) !default;
$account-reviews-review-text-font: null !default;
$account-reviews-review-text-color: null !default;
// write reviews
$account-reviews-write-reviews-position--large: absolute !default;
$account-reviews-write-reviews-right--large: 0 !default;
$account-reviews-write-reviews-top--large: 0 !default;
$account-reviews-write-reviews-width--large: rem-calc(100) !default;
// info
$account-reviews-info-padding--large: rem-calc(0 $account-reviews-write-reviews-width--large 0 0) !default;

@mixin lora-components-account-reviews {
    .c-account-reviews {
        margin: 0;
        list-style-type: none;
    }

    .c-account-reviews__item {
        position: relative;
        margin: $account-reviews-item-margin;
        padding: $account-reviews-item-padding;
        border-bottom: $account-reviews-item-border-bottom;

        @include breakpoint(large) {
            margin: $account-reviews-item-margin--large;
            padding: $account-reviews-item-padding--large;
        }

        &:last-child {
            margin: 0;
            padding: 0;
            border-bottom: none;
        }

        @include clearfix;
    }

    .c-account-reviews__image {
        float: left;
        width: $account-reviews-image-width;
        height: $account-reviews-image-height;
        margin: $account-reviews-image-margin;

        @include breakpoint(large) {
            width: $account-reviews-image-width--large;
            height: $account-reviews-image-height--large;
            margin: $account-reviews-image-margin--large;
        }
    }

    .c-account-reviews__info {
        @include breakpoint(large) {
            padding: $account-reviews-info-padding--large;
        }
    }

    .c-account-reviews__productnamelink {
        font-size: $account-reviews-productnamelink-font-size;
        font-weight: $account-reviews-productnamelink-font-weight;
        margin: $account-reviews-productnamelink-margin;
        min-height: $account-reviews-productnamelink-line-height*$account-reviews-productnamelink-font-size*$account-reviews-productnamelink-max-lines;
        line-height: $account-reviews-productnamelink-line-height;
        text-transform: $account-reviews-productnamelink-text-transform;

        @include text-line-clamp($account-reviews-productnamelink-max-lines);

        a {
            @include text-line-clamp($account-reviews-productnamelink-max-lines);

            text-decoration: none;
        }
    }

    .c-account-reviews__productsubtitle {
        font-size: $account-reviews-productsubtitle-font-size;
        color: $account-reviews-productsubtitle-color;
        margin: $account-reviews-productsubtitle-margin;
        min-height: $account-reviews-productsubtitle-line-height*$account-reviews-productsubtitle-font-size*$account-reviews-productsubtitle-max-lines;
        line-height: $account-reviews-productsubtitle-line-height;

        @include text-line-clamp($account-reviews-productsubtitle-max-lines);
    }

    .c-account-reviews__title {
        font: $account-reviews-title-font;
        text-transform: $account-reviews-title-text-transform;
        margin: $account-reviews-title-margin;
        display: inline-block;
        vertical-align: middle;
    }

    .c-account-reviews__review-text {
        margin: $account-reviews-review-text-margin;
        font: $account-reviews-review-text-font;
        color: $account-reviews-review-text-color;
    }

    .c-account-reviews__review {
        @include breakpoint(medium down) {
            clear: left;
        }
    }

    .c-account-reviews__write-reviews {
        @include breakpoint(large) {
            position: $account-reviews-write-reviews-position--large;
            #{$global-right}: $account-reviews-write-reviews-right--large;
            top: $account-reviews-write-reviews-top--large;
            width: $account-reviews-write-reviews-width--large;
            text-align: #{$global-right};
        }
    }
}
