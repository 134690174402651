@import "../../05-components/form/check-field";

$account-card-padding: rem-calc(15) !default;
$account-card-background: color(light) !default;
$account-card-border: 1px solid color(border) !default;

$account-card-data-list-margin: rem-calc(0) !default;
$account-card-data-list-font-size: null !default;
$account-card-data-list-font-size--large: null !default;
$account-card-data-list-font-weight: null !default;
$account-card-data-list-font-weight--large: null !default;
$account-card-data-list-line-height: null !default;
$account-card-data-list-line-height--large: null !default;
$account-card-data-list-color: null !default;

$account-card-nickname-font: 500 #{rem-calc(14)} / 1 $font-primary !default;
$account-card-nickname-color: color(text) !default;
$account-card-nickname-text-transform: uppercase !default;
$account-card-nickname-margin: rem-calc(0 0 15) !default;

$account-card-name-flex-basis--large: rem-calc(210) !default;
$account-card-name-padding--large: rem-calc(0 20 0 0) !default;
$account-card-name-min-width--large: rem-calc(210) !default;
$account-card-name-margin: rem-calc(0 0 16) !default;
$account-card-name-margin--large: rem-calc(0) !default;

$account-card-data-border-left--large: 1px solid color(global-background) !default;
$account-card-data-padding--large: rem-calc(0 0 0 15) !default;
$account-card-data-margin: rem-calc(0 0 16) !default;
$account-card-data-margin--large: rem-calc(0) !default;
$account-card-data-wrapper-margin: rem-calc(0 0 20 0) !default;
$account-card-data-item-shipping-option-margin: rem-calc(10 0 0) !default;

$account-card-action-link-margin: rem-calc(0) !default;
$account-card-action-link-margin--large: rem-calc(0) !default;
$account-card-action-link-text-transform: null !default;
$account-card-action-link-text-transform--large: null !default;
$account-card-action-link-color: null !default;
$account-card-action-link-color--large: null !default;
$account-card-action-link-color-hover: null !default;
$account-card-action-link-font: null !default;
$account-card-action-link-letter-spacing: null !default;

$account-card-actions-display: flex !default;
$account-card-actions-justify-content: space-between !default;
$account-card-actions-margin: rem-calc(10 0 0) !default;

$account-card-link-cursor: pointer !default;
$account-card-link-text-decoration: underline !default;
$account-card-link-text-decoration-hover: none !default;

$account-card-add-link-margin: rem-calc(0 0 15) !default;
$account-card-add-link-margin--large: rem-calc(0) !default;

$account-card-data-label-shipping-option-text-transform: uppercase !default;
$account-card-data-label-shipping-option-text-display: block !default;
$account-card-data-label-shipping-option-font: null !default;
$account-card-data-label-shipping-option-padding: 0 !default;
$account-card-data-label-shipping-option-color: null !default;
$account-card-data-label-font: null !default;
$account-card-data-label-font--large: null !default;
$account-card-data-label-color: null !default;
$account-card-data-label-color--large: null !default;
$account-card-data-label-letter-spacing: null !default;

$account-card-data-value-word-break: null !default;
$account-card-data-value-text-transform: null !default;
$account-card-data-value-font: null !default;
$account-card-data-value-font--large: null !default;
$account-card-data-value-color: null !default;
$account-card-data-value-color--large: null !default;
$account-card-data-value-letter-spacing: null !default;

@mixin lora-components-account-card {
    .c-account-card {
        padding: $account-card-padding;
        border: $account-card-border;
        background: $account-card-background;
    }

    .c-account-card__name {
        margin: $account-card-name-margin;

        @include breakpoint(large) {
            flex-basis: $account-card-name-flex-basis--large;
            min-width: $account-card-name-min-width--large;
            padding: $account-card-name-padding--large;
        }
    }

    .c-account-card__data {
        margin: $account-card-data-margin;

        @include breakpoint(large) {
            flex-grow: 1;
            margin: $account-card-data-margin--large;
        }
    }

    .c-account-card__actions {
        position: relative;
        display: $account-card-actions-display;
        justify-content: $account-card-actions-justify-content;
        margin: $account-card-actions-margin;

        .c-account-card__link {
            white-space: nowrap;
            color: inherit; // IOS fix for type "submit" button computed color being white

            @include text-style(
                $font: (
                    small: $account-card-action-link-font
                ),
                $color: (
                    small: $account-card-action-link-color,
                    large: $account-card-action-link-color--large
                ),
                $margin: (
                    small: $account-card-action-link-margin,
                    large: $account-card-action-link-margin--large
                ),
                $text-transform: (
                    small: $account-card-action-link-text-transform,
                    large: $account-card-action-link-text-transform--large
                ),
                $letter-spacing: (
                    small: $account-card-action-link-letter-spacing
                )
            );

            &:hover {
                color: $account-card-action-link-color-hover;
            }
        }
    }

    .c-account-card__nickname {
        color: $account-card-nickname-color;
        font: $account-card-nickname-font;
        margin: $account-card-nickname-margin;
        text-transform: $account-card-nickname-text-transform;

        @include breakpoint(large) {
            @include text-truncate;
        }
    }

    .c-account-card__data-list {
        list-style: none;
        margin: $account-card-data-list-margin;
        font-size: $account-card-data-list-font-size;
        font-weight: $account-card-data-list-font-weight;
        line-height: $account-card-data-list-line-height;
        color: $account-card-data-list-color;

        @include breakpoint(large) {
            font-size: $account-card-data-list-font-size--large;
            font-weight: $account-card-data-list-font-weight--large;
            line-height: $account-card-data-list-line-height--large;
        }
    }

    .c-account-card__link {
        cursor: $account-card-link-cursor;
        text-decoration: $account-card-link-text-decoration;

        &:hover,
        &:focus {
            cursor: pointer;
            text-decoration: $account-card-link-text-decoration-hover;
        }
    }

    .c-account-card__data-item.m-shipping-option {
        margin: $account-card-data-item-shipping-option-margin;

        .c-account-card__data-label {
            display: $account-card-data-label-shipping-option-text-display;
            font: $account-card-data-label-shipping-option-font;
            text-transform: $account-card-data-label-shipping-option-text-transform;
            padding: $account-card-data-label-shipping-option-padding;
            color: $account-card-data-label-shipping-option-color;
        }
    }

    .c-account-card__radio::before {
        @include lora-helper-check-field-label-before;
        @include lora-helper-check-field-radio-unchecked-state;
    }

    .c-account-card__radio.m-default,
    .c-account-card__radio:focus {
        &::before {
            @include lora-helper-check-field-radio-checked-state;
        }
    }

    .c-account-card.m-credit,
    .c-account-card.m-address {
        .c-account-card__data {
            @include breakpoint(large) {
                border-#{$global-left}: $account-card-data-border-left--large;
                padding: $account-card-data-padding--large;
            }
        }
    }

    .c-account-card__data-value {
        word-break: $account-card-data-value-word-break;

        @include text-style(
            $font: (
                small: $account-card-data-value-font,
                large: $account-card-data-value-font--large
            ),
            $color: (
                small: $account-card-data-value-color,
                large: $account-card-data-value-color--large
            ),
            $text-transform: (
                small: $account-card-data-value-text-transform
            ),
            $letter-spacing: (
                small: $account-card-data-value-letter-spacing
            )
        );
    }

    .c-account-card__data-label {
        @include text-style(
            $font: (
                small: $account-card-data-label-font,
                large: $account-card-data-label-font--large
            ),
            $color: (
                small: $account-card-data-label-color,
                large: $account-card-data-label-color--large
            ),
            $letter-spacing: (
                small: $account-card-data-label-letter-spacing
            )
        );
    }
}
