// Linked accounts
$account-linked-accounts-row-border-bottom: 1px solid color(border) !default;
$account-linked-accounts-row-border-bottom--large: null !default;
$account-linked-accounts-row-last-child-border-bottom: none !default;
$account-linked-accounts-row-padding: rem-calc(0 0 10) !default;
$account-linked-accounts-row-margin: rem-calc(0 0 10) !default;
$account-linked-accounts-row-padding--large: null !default;
$account-linked-accounts-row-margin--large: null !default;

// Description
$account-linked-accounts-description-margin: rem-calc(10 0 0) !default;
$account-linked-accounts-description-margin--large: rem-calc(5 0 0) !default;

// Icon
$account-linked-accounts-icon-color: color(text) !default;
$account-linked-accounts-icon-font: 500 #{rem-calc(18)} / 1 $font-primary !default;
$account-linked-accounts-icon-text-transform: null !default;
$account-linked-accounts-icon-letter-spacing: null !default;
$account-linked-accounts-icon-margin: rem-calc(0 20 0 0) !default;
$account-linked-accounts-icon-usesvg: true !default;
$account-linked-accounts-list: (
    facebook: (
        enabled: true,
        icon: facebook,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    google: (
        enabled: true,
        icon: google,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16),
        use-original-color: true
    ),
    twitter: (
        enabled: false,
        icon: twitter,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    line: (
        enabled: false,
        icon: lineme,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
    yahoo: (
        enabled: false,
        icon: yahoo,
        icon-height: rem-calc(16),
        icon-width: rem-calc(16)
    ),
) !default;

@mixin lora-components-account-linked-accounts {
    .c-linked-accounts__row {
        align-items: center;
        border-bottom: $account-linked-accounts-row-border-bottom;
        display: flex;
        flex-wrap: wrap;
        padding: $account-linked-accounts-row-padding;
        margin: $account-linked-accounts-row-margin;

        @include breakpoint(large) {
            border-bottom: $account-linked-accounts-row-border-bottom--large;
            padding: $account-linked-accounts-row-padding--large;
            margin: $account-linked-accounts-row-margin--large;
        }

        &:last-child {
            border-bottom: $account-linked-accounts-row-last-child-border-bottom;
            margin: 0;
        }
    }

    .c-linked-accounts__icon {
        color: $account-linked-accounts-icon-color;
        display: inline-block;
        font: $account-linked-accounts-icon-font;
        text-transform: $account-linked-accounts-icon-text-transform;
        letter-spacing: $account-linked-accounts-icon-letter-spacing;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: top;
            margin: $account-linked-accounts-icon-margin;
        }

        @each $provider, $type in $account-linked-accounts-list {
            @if (map-deep-get($account-linked-accounts-list, $provider, "enabled")) {
                &.m-#{$provider}::before {
                    // stylelint-disable max-nesting-depth
                    @if use-original-color {
                        @include svg-icon(map-deep-get($account-linked-accounts-list, $provider, "icon"), null, 100%, left, no-repeat, $usesvg: $account-linked-accounts-icon-usesvg);
                    } @else {
                        @include svg-icon(map-deep-get($account-linked-accounts-list, $provider, "icon"), $account-linked-accounts-icon-color, 100%, left, no-repeat, $usesvg: $account-linked-accounts-icon-usesvg);
                    }
                    // stylelint-enable max-nesting-depth
    
                    height: map-deep-get($account-linked-accounts-list, $provider, "icon-height");
                    width: map-deep-get($account-linked-accounts-list, $provider, "icon-width");
                }
            }
        }
    }

    .c-linked-accounts__description {
        margin: $account-linked-accounts-description-margin;

        @include breakpoint(large) {
            margin: $account-linked-accounts-description-margin--large;
        }
    }

    .c-linked-accounts__cell.m-wide {
        flex: 1 0 0;
    }

    .c-linked-accounts__cell.m-fullwidth {
        flex: 1 0 100%;
    }
}
