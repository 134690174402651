$storelocator-position: relative !default;
$storelocator-display--large: flex !default;
$storelocator-flex-wrap: wrap !default;
$storelocator-margin: rem-calc(0 0 30) !default;

$storelocator-header-margin: rem-calc(0 0 20) !default;
$storelocator-header-margin--large: 0 !default;

$storelocator-title-margin: rem-calc(0 0 10) !default;
$storelocator-title-margin--large: null !default;
$storelocator-title-font: #{rem-calc(24)} / 1 $font-primary !default;
$storelocator-title-font--large: #{rem-calc(30)} / 1 $font-primary !default;
$storelocator-title-color: null !default;
$storelocator-title-color--large: null !default;
$storelocator-title-text-transform: uppercase !default;

$storelocator-top-display: flex !default;
$storelocator-top-justify-content: space-between !default;
$storelocator-top-align-items: center !default;
$storelocator-top-margin: rem-calc(0 0 20) !default;
$storelocator-top-margin--large: null !default;
$storelocator-top-letter-spacing: null !default;

$storelocator-map-min-height: rem-calc(500) !default;
$storelocator-map-min-height--large: rem-calc(650) !default;
$storelocator-map-icon: map !default;
$storelocator-map-icon-color: color(light) !default;
$storelocator-map-icon-height: rem-calc(18) !default;
$storelocator-map-icon-width: rem-calc(12) !default;
$storelocator-map-icon-position--left: rem-calc(10) !default;
$storelocator-map-icon-usesvg: true !default;

$storelocator-map-secondary-min-height: rem-calc(190) !default;
$storelocator-map-secondary-min-height--large: rem-calc(335) !default;

$storelocator-toggle-button-text-align: null !default;
$storelocator-toggle-button-padding: null !default;
$storelocator-book-button-margin: rem-calc(10 0 0 0) !default;
$storelocator-book-button-margin--large: 0 !default;

$storelocator-list-icon: burger !default;
$storelocator-list-icon-color: color(light) !default;
$storelocator-list-icon-height: rem-calc(18) !default;
$storelocator-list-icon-width: rem-calc(12) !default;
$storelocator-list-icon-position--left: rem-calc(10);
$storelocator-list-icon-usesvg: true !default;

$storelocator-actions-display: flex !default;
$storelocator-actions-inline-display: flex !default;
$storelocator-actions-inline-search-action-margin: rem-calc(0 10 0 0) !default;

$storelocator-action-margin: rem-calc(0 0 15) !default;
$storelocator-search-action-margin: 0 !default;
$storelocator-search-action-margin--large: rem-calc(0 10 0 0) !default;

$storelocator-toggle-button-position: relative !default;
$storelocator-toggle-button-min-width: rem-calc(100) !default;
$storelocator-toggle-button-hover-icon-color: null !default;

$storelocator-sidebar-width: 50% !default;
$storelocator-sidebar-padding: rem-calc(0 10 0 0) !default;

@mixin lora-components-storelocator {
    .c-storelocator {
        position: $storelocator-position;
        margin: $storelocator-margin;

        &.m-map-view {
            .c-storelocator__map {
                min-height: $storelocator-map-min-height;
            }

            .c-storelocator__list-text {
                display: inline-block;
            }

            .c-storelocator__results,
            .c-storelocator__map-text {
                display: none;
            }
        }

        @include breakpoint(large) {
            display: $storelocator-display--large;
            flex-wrap: $storelocator-flex-wrap;
        }

        &.m-secondary {
            .c-storelocator__map {
                min-height: $storelocator-map-secondary-min-height;

                @include breakpoint(large) {
                    height: $storelocator-map-secondary-min-height--large;
                }
            }
        }
    }

    .c-storelocator__header {
        margin: $storelocator-header-margin;

        @include breakpoint(large) {
            flex-basis: 100%;
            margin: $storelocator-header-margin--large;
        }
    }

    .c-storelocator__title {
        @include text-style(
            $font: (
                small: $storelocator-title-font,
                large: $storelocator-title-font--large
            ),
            $margin: (
                small: $storelocator-title-margin,
                large: $storelocator-title-margin--large
            ),
            $text-transform: (
                small: $storelocator-title-text-transform
            ),
            $color: (
                small: $storelocator-title-color,
                large: $storelocator-title-color--large
            )
        );
    }

    .c-storelocator__top {
        display: $storelocator-top-display;
        justify-content: $storelocator-top-justify-content;
        align-items: $storelocator-top-align-items;
        margin: $storelocator-top-margin;
        letter-spacing: $storelocator-top-letter-spacing;

        @include breakpoint(large) {
            flex-basis: 100%;
            margin: $storelocator-top-margin--large;
        }
    }

    .c-storelocator__map {
        @include breakpoint(large) {
            height: $storelocator-map-min-height--large;
            flex-grow: 1;
        }
    }

    .c-storelocator__map-text {
        &::before {
            @include svg-icon($storelocator-map-icon, $storelocator-map-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-map-icon-usesvg);

            content: '';
            @include vertical-center;

            height: $storelocator-map-icon-height;
            width: $storelocator-map-icon-width;
            #{$global-left}: $storelocator-map-icon-position--left;
        }
    }

    .c-storelocator__list-text {
        display: none;

        &::before {
            @include svg-icon($storelocator-list-icon, $storelocator-list-icon-color, 100%, left, no-repeat, $usesvg: $storelocator-list-icon-usesvg);

            content: '';
            @include vertical-center;

            height: $storelocator-list-icon-height;
            width: $storelocator-list-icon-width;
            #{$global-left}: $storelocator-list-icon-position--left;
        }
    }

    .c-storelocator__actions {
        @include breakpoint(large) {
            display: $storelocator-actions-display;
        }

        &.m-inline {
            display: $storelocator-actions-inline-display;

            .c-storelocator__action.m-search {
                margin: $storelocator-actions-inline-search-action-margin;
            }
        }
    }

    .c-storelocator__action {
        margin: $storelocator-action-margin;
        width: 100%;

        &.m-search {
            margin: $storelocator-search-action-margin;

            @include breakpoint(large) {
                margin: $storelocator-search-action-margin--large;
            }
        }
    }

    .c-storelocator__toggle-button {
        position: $storelocator-toggle-button-position;
        min-width: $storelocator-toggle-button-min-width;
        text-align: $storelocator-toggle-button-text-align;
        padding: $storelocator-toggle-button-padding;
        @include hide-for(large);

        @if ($storelocator-toggle-button-hover-icon-color) {
            &:hover,
            &:focus {
                .c-storelocator__list-text::before {
                    @include svg-icon($storelocator-list-icon, $storelocator-toggle-button-hover-icon-color, 100%, left, no-repeat, $color-change: true, $usesvg: $storelocator-list-icon-usesvg);
                }

                .c-storelocator__map-text::before {
                    @include svg-icon($storelocator-map-icon, $storelocator-toggle-button-hover-icon-color, 100%, left, no-repeat, $color-change: true, $usesvg: $storelocator-map-icon-usesvg);
                }
            }
        }
    }

    .c-storelocator__sidebar {
        @include breakpoint(large) {
            width: $storelocator-sidebar-width;
            padding: $storelocator-sidebar-padding;
            flex-basis: 35%;
        }
    }

    .c-storelocator__book-button {
        margin: $storelocator-book-button-margin;

        @include breakpoint(large) {
            margin: $storelocator-book-button-margin--large;
        }
    }

    .c-storelocator__content {
        @include breakpoint(large) {
            flex-basis: 100%;
        }
    }
}
