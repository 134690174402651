$account-table-orderlist-ship-number-max-width: rem-calc(220) !default;
$account-table-orderlist-ship-number-word-wrap: break-word !default;

@mixin my-components-account-table-orderlist {
    .c-account-table__cell {
        &.m-ship-number {
            max-width: $account-table-orderlist-ship-number-max-width;
            word-wrap: $account-table-orderlist-ship-number-word-wrap;
        }
    }
}
