$account-profilephoneverification-submit-wrapper-div-flex: 1 1 100% !default;
$account-profilephoneverification-submit-wrapper-div-margin: 0 rem-calc(10) rem-calc(15) !default;

@mixin apaclayer-components-account-profilephoneverification {
    @include breakpoint(small only) {
        .c-form__submit-wrapper {
            flex: $account-profilephoneverification-submit-wrapper-div-flex;
            margin: $account-profilephoneverification-submit-wrapper-div-margin;
        }
    }
}
