/*
* Styling for Mobile Validation Modals
*/
$modal-mobilevalidation-text-instructions-margin: rem-calc(20 0);
$modal-mobilevalidation-text-instructions-padding: rem-calc(0 10);

@mixin apaclayer-components-modal-mobilevalidation {
    .c-mobilevalidation {
        &__form {
            &--instructions {
                margin: $modal-mobilevalidation-text-instructions-margin;
            }
        }

        &--asset {
            margin: $modal-mobilevalidation-text-instructions-margin;
            padding: $modal-mobilevalidation-text-instructions-padding;
            text-align: center;
        }

        .m-center {
            align-items: center;
            text-align: center;
        }
    }
}
