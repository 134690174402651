$account-preferences-table-row-border-top: 1px solid color(border) !default;
$account-preferences-table-row-padding: rem-calc(15 0 0) !default;
$account-preferences-table-row-margin: rem-calc(0) !default;
$account-preferences-table-group-actions-max-width: rem-calc(150) !default;

@mixin lora-components-account-preferences-table {
    .c-account-preferences-table {
        .c-form__row-title {
            flex-grow: 1;
        }
    }

    .c-account-preferences-table__row {
        border-top: $account-preferences-table-row-border-top;
        margin: $account-preferences-table-row-margin;
        padding: $account-preferences-table-row-padding;

        &:first-child {
            border-top: none;
            padding-top: 0;
        }
    }

    .c-account-preferences-table__group-actions {
        max-width: $account-preferences-table-group-actions-max-width;
    }

    .c-account-preferences-table__actions {
        display: flex;
        flex: 1 1 auto;
        white-space: nowrap;
    }
}
