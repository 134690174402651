// Deprecated components
@import "../../100-deprecated/account/account-summary-table";

$order-details-summary-table-font-size: $account-summary-table-font-size !default;
$order-details-summary-table-border-collapse: collapse !default;
$order-details-summary-table-cell-padding: $account-summary-table-cell-padding !default;

$order-details-summary-table-label-font: null !default;
$order-details-summary-table-label-font-weight: $account-summary-table-label-font-weight !default;
$order-details-summary-table-label-text-transform: $account-summary-table-label-text-transform !default;
$order-details-summary-table-label-color: null !default;
$order-details-summary-table-label-text-align: $account-summary-table-label-text-align !default;
$order-details-summary-table-label-width--large: $account-summary-table-label-width--large !default;
$order-details-summary-table-label-letter-spacing: null !default;

$order-details-summary-table-value-font: null !default;
$order-details-summary-table-value-text-align: $account-summary-table-value-text-align !default;
$order-details-summary-table-value-color: null !default;
$order-details-summary-table-value-font-weight: null !default;
$order-details-summary-table-value-letter-spacing: null !default;

$order-details-summary-table-cell-total-font: $account-summary-table-cell-total-font !default;
$order-details-summary-table-cell-total-padding: $account-summary-table-cell-total-padding !default;
$order-details-summary-table-cell-total-color: null !default;
$order-details-summary-table-cell-total-text-transform: uppercase !default;
$order-details-summary-table-cell-total-text-align: null !default;

$order-details-summary-table-row-total-border-top: null !default;
$order-details-summary-table-taxlabel-text-align: null !default;

@mixin lora-components-order-details-summary-table {
    .c-order-details-summary-table {
        font-size: $order-details-summary-table-font-size;
        table-layout: fixed;
        width: 100%;
        border-collapse: $order-details-summary-table-border-collapse;
    }

    .c-order-details-summary-table__cell {
        padding: $order-details-summary-table-cell-padding;

        &.m-label {
            font: $order-details-summary-table-label-font;
            font-weight: $order-details-summary-table-label-font-weight;
            text-align: $order-details-summary-table-label-text-align;
            text-transform: $order-details-summary-table-label-text-transform;
            color: $order-details-summary-table-label-color;
            letter-spacing: $order-details-summary-table-label-letter-spacing;

            @include breakpoint(large) {
                width: $order-details-summary-table-label-width--large;
            }
        }

        &.m-value {
            text-align: $order-details-summary-table-value-text-align;
            color: $order-details-summary-table-value-color;
            font: $order-details-summary-table-value-font;
            font-weight: $order-details-summary-table-value-font-weight;
            letter-spacing: $order-details-summary-table-value-letter-spacing;
        }

        &.m-small {
            text-align: $order-details-summary-table-label-text-align;
            color: $order-details-summary-table-label-color;
            font: $order-details-summary-table-label-font;
            font-weight: $order-details-summary-table-label-font-weight;
            letter-spacing: $order-details-summary-table-label-letter-spacing;
        }

        &.m-taxlabel {
            text-align: $order-details-summary-table-taxlabel-text-align;
        }
    }

    .c-order-details-summary-table__item.m-total {
        border-top: $order-details-summary-table-row-total-border-top;

        .c-order-details-summary-table__cell {
            font: $order-details-summary-table-cell-total-font;
            padding: $order-details-summary-table-cell-total-padding;
            color: $order-details-summary-table-cell-total-color;
            text-transform: $order-details-summary-table-cell-total-text-transform;
            text-align: $order-details-summary-table-cell-total-text-align;
        }
    }
}
