// Coupon card content
$coupon-card-content-padding: rem-calc(10 8 14) !default;
$coupon-card-content-background-color: color(primary) !default;
$coupon-card-content-background: linear-gradient(45deg, $coupon-card-content-background-color 0%, lighten($coupon-card-content-background-color, 32%) 78%, lighten($coupon-card-content-background-color, 27%) 100%) !default;
$coupon-card-content-border-radius: rem-calc(4 4 0 0) !default;
$coupon-card-content-box-shadow: rem-calc(0 -3 5) rgba(color(dark), 0.3) !default;
$coupon-card-content-disabled-background-color: color(disabled) !default;
$coupon-card-content-disabled-background: linear-gradient(45deg, $coupon-card-content-disabled-background-color 0%, lighten($coupon-card-content-disabled-background-color, 65%) 78%, lighten($coupon-card-content-disabled-background-color, 30%) 100%) !default;
$coupon-card-content-expired-background-color: color(global-background) !default;
$coupon-card-content-expired-background: linear-gradient(45deg, $coupon-card-content-disabled-background-color 0%, lighten($coupon-card-content-expired-background-color, 66%) 70%) !default;
$coupon-card-content-expired-opacity: 0.5 !default;
$coupon-card-content-expired-box-shadow: 0 0 4px 0 rgba(color(dark), 0.5) !default;
// Coupon card actions
$coupon-card-actions-padding: rem-calc(8) !default;
$coupon-card-actions-box-shadow: rem-calc(0 3 5) rgba(color(dark), 0.3) !default;
$coupon-card-actions-border-radius: rem-calc(0 0 4 4) !default;
// Coupon card actions inner
$coupon-card-actions-inner-min-height: rem-calc(35) !default;
$coupon-card-actions-inner-line-height: 1.2 !default;
// Coupon card actions ::before
$coupon-card-actions-before-left: rem-calc(10) !default;
$coupon-card-actions-before-right: rem-calc(10) !default;
$coupon-card-actions-before-height: rem-calc(3) !default;
$coupon-card-actions-before-background-image: "coupon-repeat-bg.png" !default;
$coupon-card-actions-before-background: url('#{$global-image-path}/#{$coupon-card-actions-before-background-image}') repeat left center/contain !default;
$coupon-card-actions-icon-size: rem-calc(12) !default;
$coupon-card-actions-icon-background-color: color(dark) !default;
$coupon-card-actions-applied-label-padding: rem-calc(0 8 0 0) !default;
/// Coupon code
$coupon-card-code-font-weight: 500 !default;
$coupon-card-code-font-weight--large: $coupon-card-code-font-weight !default;
$coupon-card-code-font-family: $font-primary !default;
$coupon-card-code-font-family--large: $coupon-card-code-font-family !default;
$coupon-card-code-font-size: rem-calc(11) !default;
$coupon-card-code-font-size--large: $coupon-card-code-font-size !default;
$coupon-card-code-line-height: 1.2 !default;
$coupon-card-code-line-height--large: $coupon-card-code-line-height !default;
$coupon-card-code-max-lines: 1 !default;
$coupon-card-code-max-lines--large: $coupon-card-code-max-lines !default;
$coupon-card-code-font: $coupon-card-code-font-weight #{$coupon-card-code-font-size} / $coupon-card-code-line-height $coupon-card-code-font-family !default;
$coupon-card-code-font--large: $coupon-card-code-font-weight--large #{$coupon-card-code-font-size--large} / $coupon-card-code-line-height--large $coupon-card-code-font-family--large !default;
$coupon-card-code-min-height: $coupon-card-code-line-height*$coupon-card-code-font-size*$coupon-card-code-max-lines;
$coupon-card-code-min-height--large: $coupon-card-code-line-height--large*$coupon-card-code-font-size--large*$coupon-card-code-max-lines--large;
/// Coupon name
$coupon-card-name-font-weight: 700 !default;
$coupon-card-name-font-weight--large: $coupon-card-name-font-weight !default;
$coupon-card-name-font-family: $font-primary !default;
$coupon-card-name-font-family--large: $coupon-card-name-font-family !default;
$coupon-card-name-font-size: rem-calc(14) !default;
$coupon-card-name-font-size--large: $coupon-card-name-font-size !default;
$coupon-card-name-line-height: 1.2 !default;
$coupon-card-name-line-height--large: $coupon-card-name-line-height !default;
$coupon-card-name-max-lines: 1 !default;
$coupon-card-name-max-lines--large: $coupon-card-name-max-lines !default;
$coupon-card-name-font: $coupon-card-name-font-weight #{$coupon-card-name-font-size} / $coupon-card-name-line-height $coupon-card-name-font-family !default;
$coupon-card-name-font--large: $coupon-card-name-font-weight--large #{$coupon-card-name-font-size--large} / $coupon-card-name-line-height--large $coupon-card-name-font-family--large !default;
$coupon-card-name-min-height: $coupon-card-name-line-height*$coupon-card-name-font-size*$coupon-card-name-max-lines;
$coupon-card-name-min-height--large: $coupon-card-name-line-height--large*$coupon-card-name-font-size--large*$coupon-card-name-max-lines--large;
$coupon-card-icon-background-color: color(info) !default;
$coupon-card-qualify-link-margin: rem-calc(0 0 0 4) !default;

@mixin lora-components-coupon-card {
    .c-coupon-card__code {
        font: $coupon-card-code-font;
        min-height: $coupon-card-code-min-height;

        @include text-line-clamp($coupon-card-code-max-lines);

        @include breakpoint(large) {
            font: $coupon-card-code-font--large;
            min-height: $coupon-card-code-min-height--large;

            @include text-line-clamp($coupon-card-code-max-lines--large);
        }
    }

    .c-coupon-card__form {
        width: 100%;
    }

    .c-coupon-card__name {
        font: $coupon-card-name-font;
        min-height: $coupon-card-name-min-height;

        @include text-line-clamp($coupon-card-name-max-lines);

        @include breakpoint(large) {
            font: $coupon-card-name-font--large;
            min-height: $coupon-card-name-min-height--large;

            @include text-line-clamp($coupon-card-name-max-lines--large);
        }
    }

    .c-coupon-card__content {
        padding: $coupon-card-content-padding;
        background: $coupon-card-content-background;
        border-radius: $coupon-card-content-border-radius;
        box-shadow: $coupon-card-content-box-shadow;
    }

    .c-coupon-card__actions {
        position: relative;
        padding: $coupon-card-actions-padding;
        box-shadow: $coupon-card-actions-box-shadow;
        border-radius: $coupon-card-actions-border-radius;

        &::before {
            content: '';
            position: absolute;
            left: $coupon-card-actions-before-left;
            right: $coupon-card-actions-before-right;
            bottom: 100%;
            height: $coupon-card-actions-before-height;
            background: $coupon-card-actions-before-background;
        }

        .c-icon.m-tick .c-icon__svg::before {
            width: $coupon-card-actions-icon-size;
            background-color: $coupon-card-actions-icon-background-color;
        }
    }

    .c-coupon-card__actions-inner {
        min-height: $coupon-card-actions-inner-min-height;
        line-height: $coupon-card-actions-inner-line-height;
    }

    .c-coupon-card__redemption {
        position: relative;
        display: flex;
        white-space: nowrap;
    }

    .c-coupon-card__actions-applied-label {
        padding: $coupon-card-actions-applied-label-padding;
    }

    .c-coupon-card.m-disabled {
        .c-coupon-card__content {
            background: $coupon-card-content-disabled-background;
        }

        .c-icon.m-info .c-icon__svg::before {
            background-color: $coupon-card-icon-background-color;
        }
    }

    .c-coupon-card.m-expired {
        opacity: $coupon-card-content-expired-opacity;
        box-shadow: $coupon-card-content-expired-box-shadow;

        .c-coupon-card__content {
            background: $coupon-card-content-expired-background;
        }
    }

    .c-coupon-card__qualify-link {
        margin: $coupon-card-qualify-link-margin;
    }
}
