$product-table-allocation-qty-margin: rem-calc(0 20 0 0) !default;
$product-table-allocation-qty-margin--large: rem-calc(0 0 35 0) !default;
$product-table-allocation-qty-width: rem-calc(100) !default;
$product-table-allocation-status-flex: 1 1 auto !default;
$product-table-allocation-status-color: color(text-secondary) !default;
$product-table-allocation-status-font: #{rem-calc(12)} / 1.17 $font-primary !default;
$product-table-allocation-status-font--large: #{rem-calc(12)} / 1.5 $font-primary !default;
$product-table-allocation-qty-padding: rem-calc(0 0 0 1) !default;
$product-table-allocation-flex-direction: null !default;
$product-table-allocation-flex-direction--large: column !default;
$product-table-allocation-align-items: null !default;
$product-table-allocation-align-items--large: null !default;
$product-table-allocation-status-text-align: $global-right !default;
$product-table-allocation-status-text-align--large: $global-left !default;

@mixin lora-components-product-table-allocation {
    .c-product-table-allocation {
        display: flex;
        flex-flow: $product-table-allocation-flex-direction;
        align-items: $product-table-allocation-align-items;

        @include breakpoint(large) {
            flex-flow: $product-table-allocation-flex-direction--large;
            align-items: $product-table-allocation-align-items--large;
        }
    }

    .c-product-table-allocation__qty {
        flex: 0 0 auto;
        margin: $product-table-allocation-qty-margin;
        padding: $product-table-allocation-qty-padding;
        width: $product-table-allocation-qty-width;

        @include breakpoint(large) {
            margin: $product-table-allocation-qty-margin--large;
        }
    }

    .c-product-table-allocation__status {
        flex: $product-table-allocation-status-flex;
        color: $product-table-allocation-status-color;
        font: $product-table-allocation-status-font;
        text-align: $product-table-allocation-status-text-align;

        @include breakpoint(large) {
            font: $product-table-allocation-status-font--large;
            text-align: $product-table-allocation-status-text-align--large;
        }
    }
}
