// Could be used by NGL-based brands before 4.0.0 release
// Variables migrated to 05-components/order/_order-details-summary-table.scss

$account-summary-table-font-size: rem-calc(12) !default; // @new: $order-details-summary-table-font-size: $account-summary-table-font-size !default;
$account-summary-table-cell-padding: rem-calc(3 0) !default; // @new: $order-details-summary-table-cell-padding: $account-summary-table-cell-padding !default;
$account-summary-table-label-font-weight: 500 !default; // @new: $order-details-summary-table-label-font-weight: $account-summary-table-label-font-weight !default;
$account-summary-table-label-text-transform: uppercase !default; // @new: $order-details-summary-table-label-text-transform: $account-summary-table-label-text-transform !default;
$account-summary-table-label-text-align: $global-left !default; // @new: $order-details-summary-table-label-text-align: $account-summary-table-label-text-align !default;
$account-summary-table-value-text-align: $global-right !default; // @new: $order-details-summary-table-value-text-align: $account-summary-table-value-text-align !default;
$account-summary-table-cell-total-font: bold #{rem-calc(12)} / 1 $font-primary !default; // @new: $order-details-summary-table-cell-total-font: $account-summary-table-cell-total-font !default;
$account-summary-table-cell-total-padding: rem-calc(35 0 0) !default; // @new: $order-details-summary-table-cell-total-padding: $account-summary-table-cell-total-padding !default;
$account-summary-table-label-width--large: 65% !default; // @new: $order-details-summary-table-label-width--large: $account-summary-table-label-width--large !default;
