$account-order-details-summary-margin: rem-calc(2 0 20) !default;
$account-order-details-summary-margin--large: rem-calc(0 0 20) !default;
$account-order-details-summary-background: color(light) !default;
// Title
$account-order-details-summary-title-margin: rem-calc(30 0 25) !default;
$account-order-details-summary-title-font: 500 #{rem-calc(18)} / 1.2 $font-primary !default;
$account-order-details-summary-title-text-transform: uppercase !default;
$account-order-details-summary-title-text-align: center !default;

@mixin lora-components-account-order-details-summary {
    .c-order-details-summary {
        background: $account-order-details-summary-background;
        margin: $account-order-details-summary-margin;
        overflow: hidden; // prevent margin collapse

        @include breakpoint(large) {
            margin: $account-order-details-summary-margin--large;
        }
    }

    .c-order-details-summary__title {
        @include hide-for(medium down);

        font: $account-order-details-summary-title-font;
        margin: $account-order-details-summary-title-margin;
        text-align: $account-order-details-summary-title-text-align;
        text-transform: $account-order-details-summary-title-text-transform;
    }
}
