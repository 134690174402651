// Actions
$product-table-details-actions-margin: auto 0 0 !default;
$product-table-details-actions-margin--large: null !default;
$product-table-details-actions-padding: rem-calc(10 0 0) !default;
$product-table-details-actions-padding--large: null !default;
$product-table-details-actions-white-space: null !default;
$product-table-details-actions-font: null !default;
$product-table-details-actions-display: null !default;
$product-table-details-actions-display--large: null !default;
$product-table-details-actions-justify-content: null !default;
$product-table-details-actions-justify-content--large: null !default;

// Action
$product-table-details-action-text-transform: null !default;
$product-table-details-action-text-transform--large: null !default;
$product-table-details-action-margin: rem-calc(0 15 0 0) !default;
$product-table-details-action-margin--large: rem-calc(0 25 0 0) !default;
$product-table-details-action-last-padding--large: rem-calc(5 0 0) !default;
$product-table-details-action-color: color(text-secondary) !default;
$product-table-details-action-color-hover: color(primary-active) !default;
$product-table-details-action-text-decoration: underline !default;
$product-table-details-action-text-decoration-hover: underline !default;
$product-table-details-action-font: null !default;
$product-table-details-action-font--large: null !default;

// Vertical Actions
$product-table-details-actions-vertical-display: flex !default;
$product-table-details-actions-vertical-flex-direction: column !default;
$product-table-details-actions-vertical-align-items: flex-start !default;

// Vertical Action
$product-table-details-action-vertical-margin: rem-calc(0 0 16) !default;
$product-table-details-action-vertical-padding: 0 !default;
$product-table-details-action-vertical-last-child-margin: 0 !default;

// Action Show Icon
$product-table-details-action-show-icon-display: inline-flex !default;
$product-table-details-action-show-icon-align-items: center !default;
$product-table-details-action-icon-size: rem-calc(12) !default;
$product-table-details-action-icon-color: $product-table-details-action-color !default;
$product-table-details-action-icon-hover-color: $product-table-details-action-color-hover !default;
$product-table-details-action-icon-edit: edit !default;
$product-table-details-action-icon-edit-usesvg: true !default;
$product-table-details-action-icon-wishlist: heart-outline !default;
$product-table-details-action-icon-wishlist-usesvg: true !default;
$product-table-details-action-icon-remove: cross !default;
$product-table-details-action-icon-remove-usesvg: true !default;
$product-table-details-action-icon-margin: rem-calc(0 5 0 0) !default;

$product-table-details-brand-margin: rem-calc(0) !default;
$product-table-details-brand-margin--large: 0 !default;
$product-table-details-subtitle-color: color(text-secondary) !default;
$product-table-details-subtitle-font: null !default;
$product-table-details-subtitle-font--large: null !default;
$product-table-details-subtitle-text-transform: null !default;
$product-table-details-subtitle-margin: null !default;
$product-table-details-subtitle-margin--large: null !default;
$product-table-details-subtitle-letter-spacing: null !default;
$product-table-details-title-margin: rem-calc(0) !default;
$product-table-details-title-margin--large: rem-calc(0 0 7) !default;
$product-table-details-title-action-text-decoration: none !default;
$product-table-details-title-action-text-transform: null !default;
$product-table-details-title-action-hover-text-decoration: none !default;
$product-table-details-title-action-color: color(text) !default;
$product-table-details-title-action-hover-color: color(primary-active) !default;
$product-table-details-title-action-text-align: null !default;
$product-table-details-title-font: rem-calc(14) $font-primary !default;
$product-table-details-title-font--large: null !default;
$product-table-details-title-action-text-align: left !default;
$product-table-details-title-action-font: $product-table-details-title-font !default;
$product-table-details-title-action-font--large: $product-table-details-title-font--large !default;
$product-table-details-title-text-transform: none !default;
$product-table-details-font: null !default;
$product-table-details-min-height--large: rem-calc(170) !default;
$product-table-details-info-font: null !default;
$product-table-details-info-text-transform: null !default;
$product-table-details-info-color: null !default;
$product-table-details-link-text-decoration: none !default;
$product-table-details-link-color: null !default;
$product-table-details-promo-exclusion-background: color(global-background) !default;
$product-table-details-promo-exclusion-color: color(text-secondary) !default;
$product-table-details-promo-exclusion-font: rem-calc(12) $font-primary !default;
$product-table-details-promo-exclusion-padding: rem-calc(5 20) !default;
// Bundle
$product-table-details-bundle-title-name-display: none !default;
$product-table-details-bundle-item-last-child-margin: rem-calc(0 0 5) !default;
$product-table-details-bundle-margin: null !default;
$product-table-details-bundle-margin--large: null !default;

// Gift info
$product-table-details-gift-color: color(text) !default;
$product-table-details-gift-color--large: null !default;
$product-table-details-gift-margin: rem-calc(0 0 8) !default;
$product-table-details-gift-margin--large: null !default;
$product-table-details-gift-font: null !default;
$product-table-details-gift-font--large: null !default;
$product-table-details-gift-label-font: bold rem-calc(14) $font-primary !default;
$product-table-details-gift-word-break: break-word !default;

// message
$product-table-details-message-color: color(text-secondary) !default;
$product-table-details-message-font: null !default;
$product-table-details-message-padding: rem-calc(10 0 0 50) !default;
$product-table-details-message-icon-height: rem-calc(36) !default;
$product-table-details-message-icon-width: rem-calc(36) !default;
$product-table-details-message-icon-top: rem-calc(15) !default;
$product-table-details-message-icon: alert !default;
$product-table-details-message-icon-color: color(primary) !default;
$product-table-details-message-icon-usesvg: true !default;
$product-table-details-gift-message-font: null !default;
$product-table-details-gift-message-font--large: null !default;
$product-table-details-gift-message-color: null !default;
$product-table-details-gift-message-color--large: null !default;
$product-table-details-gift-message-word-break: break-word !default;
$product-table-details-gift-text-font: null !default;
$product-table-details-gift-text-font--large: null !default;

@mixin lora-components-product-table-details {
    .c-product-table-details {
        display: flex;
        flex-flow: column;
        font: $product-table-details-font;

        @include breakpoint(large) {
            flex: 1;
            min-height: $product-table-details-min-height--large;
        }

        &.m-auto-height {
            min-height: auto;
        }
    }

    // Bundle
    .c-product-table-details__bundle {
        margin: $product-table-details-bundle-margin;

        @include breakpoint(large) {
            margin: $product-table-details-bundle-margin--large;
        }

        .c-product-bundle__title-name {
            display: $product-table-details-bundle-title-name-display;
        }

        .c-product-bundle__item:last-child {
            margin: $product-table-details-bundle-item-last-child-margin;
        }
    }

    .c-product-table-details__info {
        flex-grow: 1;
        font: $product-table-details-info-font;
        text-transform: $product-table-details-info-text-transform;
        color: $product-table-details-info-color;

        & > :last-child {
            margin-bottom: 0;
        }
    }

    .c-product-table-details__actions {
        margin: $product-table-details-actions-margin;
        padding: $product-table-details-actions-padding;
        white-space: $product-table-details-actions-white-space;
        font: $product-table-details-actions-font;
        display: $product-table-details-actions-display;
        justify-content: $product-table-details-actions-justify-content;

        @include breakpoint(large) {
            margin: $product-table-details-actions-margin--large;
            padding: $product-table-details-actions-padding--large;
            display: $product-table-details-actions-display--large;
            justify-content: $product-table-details-actions-justify-content--large;
        }

        &:empty {
            display: none;
        }

        &.m-vertical-display {
            display: $product-table-details-actions-vertical-display;
            flex-flow: $product-table-details-actions-vertical-flex-direction;
            align-items: $product-table-details-actions-vertical-align-items;

            .c-product-table-details__action {
                margin: $product-table-details-action-vertical-margin;
                padding: $product-table-details-action-vertical-padding;

                &:last-child {
                    margin: $product-table-details-action-vertical-last-child-margin;
                }
            }
        }

        &.m-show-icons .c-product-table-details__action {
            display: $product-table-details-action-show-icon-display;
            align-items: $product-table-details-action-show-icon-align-items;

            &::before {
                content: "";
                display: inline-block;
                height: $product-table-details-action-icon-size;
                width: $product-table-details-action-icon-size;
                margin: $product-table-details-action-icon-margin;
            }

            &.m-edit {
                &::before {
                    @include svg-icon($product-table-details-action-icon-edit, $product-table-details-action-icon-color, contain, center, no-repeat, $usesvg: $product-table-details-action-icon-edit-usesvg);
                }

                &:hover::before,
                &:focus::before {
                    @include svg-icon($product-table-details-action-icon-edit, $product-table-details-action-icon-hover-color, contain, left, no-repeat, $color-change: true, $usesvg: $product-table-details-action-icon-edit-usesvg);
                }
            }

            &.m-wishlist {
                &::before {
                    @include svg-icon($product-table-details-action-icon-wishlist, $product-table-details-action-icon-color, contain, center, no-repeat, $usesvg: $product-table-details-action-icon-wishlist-usesvg);
                }

                &:hover::before,
                &:focus::before {
                    @include svg-icon($product-table-details-action-icon-wishlist, $product-table-details-action-icon-hover-color, contain, left, no-repeat, $color-change: true, $usesvg: $product-table-details-action-icon-wishlist-usesvg);
                }
            }

            &.m-remove {
                &::before {
                    @include svg-icon($product-table-details-action-icon-remove, $product-table-details-action-icon-color, contain, center, no-repeat, $usesvg: $product-table-details-action-icon-remove-usesvg);
                }

                &:hover::before,
                &:focus::before {
                    @include svg-icon($product-table-details-action-icon-remove, $product-table-details-action-icon-hover-color, contain, left, no-repeat, $color-change: true, $usesvg: $product-table-details-action-icon-remove-usesvg);
                }
            }
        }
    }

    .c-product-table-details__action {
        cursor: pointer;
        white-space: nowrap;

        @include text-style(
            $font: (
                small: $product-table-details-action-font,
                large: $product-table-details-action-font--large
            ),
            $margin: (
                small: $product-table-details-action-margin,
                large: $product-table-details-action-margin--large
            ),
            $text-decoration: (
                small: $product-table-details-action-text-decoration
            ),
            $text-transform: (
                small: $product-table-details-action-text-transform,
                large: $product-table-details-action-text-transform--large
            ),
            $color: (
                small: $product-table-details-action-color
            )
        );

        @include hover {
            &:hover {
                color: $product-table-details-action-color-hover;
                text-decoration: $product-table-details-action-text-decoration-hover;
            }
        }

        &:last-child {
            margin: 0;

            @include breakpoint(large) {
                padding: $product-table-details-action-last-padding--large;
            }
        }
    }

    .c-product-table__wishlist-remove {
        display: none;
    }

    .c-product-table__wishlist.m-active {
        .c-product-table__wishlist-remove {
            display: inline;
        }

        .c-product-table__wishlist-add {
            display: none;
        }
    }

    .c-product-table-details__promo-exclusion {
        background: $product-table-details-promo-exclusion-background;
        color: $product-table-details-promo-exclusion-color;
        font: $product-table-details-promo-exclusion-font;
        padding: $product-table-details-promo-exclusion-padding;
    }
    // Elements

    .c-product-table-details__brand {
        margin: $product-table-details-brand-margin;

        @include breakpoint(large) {
            margin: $product-table-details-brand-margin--large;
        }
    }

    .c-product-table-details__title {
        font: $product-table-details-title-font;
        margin: $product-table-details-title-margin;
        text-transform: $product-table-details-title-text-transform;

        @include breakpoint(large) {
            font: $product-table-details-title-font--large;
            margin: $product-table-details-title-margin--large;
        }

        .c-product-table-details__action {
            font: $product-table-details-title-action-font;
            color: $product-table-details-title-action-color;
            text-decoration: $product-table-details-title-action-text-decoration;
            text-transform: $product-table-details-title-action-text-transform;
            text-align: $product-table-details-title-action-text-align;
            white-space: normal;

            &:hover,
            &:focus {
                color: $product-table-details-title-action-hover-color;
                text-decoration: $product-table-details-title-action-hover-text-decoration;
            }

            @include breakpoint(large) {
                font: $product-table-details-title-action-font--large;
            }
        }
    }

    .c-product-table-details__link {
        text-decoration: $product-table-details-link-text-decoration;
        color: $product-table-details-link-color;
    }

    .c-product-table-details__subtitle {
        color: $product-table-details-subtitle-color;
        font: $product-table-details-subtitle-font;
        text-transform: $product-table-details-subtitle-text-transform;
        margin: $product-table-details-subtitle-margin;
        letter-spacing: $product-table-details-subtitle-letter-spacing;

        @include breakpoint(large) {
            font: $product-table-details-subtitle-font--large;
            margin: $product-table-details-subtitle-margin--large;
        }
    }

    .c-product-table-details__message {
        color: $product-table-details-message-color;
        font: $product-table-details-message-font;
        padding: $product-table-details-message-padding;
        position: relative;

        .c-product-table-details__text {
            &::before {
                @include svg-icon($product-table-details-message-icon, $product-table-details-message-icon-color, 100%, left, no-repeat, $usesvg: $product-table-details-message-icon-usesvg);

                position: absolute;
                top: $product-table-details-message-icon-top;
                content: '';
                height: $product-table-details-message-icon-height;
                width: $product-table-details-message-icon-width;
                #{$global-left}: 0;
            }
        }
    }

    .c-product-table-details__gift {
        word-break: $product-table-details-gift-word-break;

        @include text-style(
            $font: (
                small: $product-table-details-gift-font,
                large: $product-table-details-gift-font--large
            ),
            $margin: (
                small: $product-table-details-gift-margin,
                large: $product-table-details-gift-margin--large
            ),
            $color: (
                small: $product-table-details-gift-color,
                large: $product-table-details-gift-color--large
            )
        );
    }

    .c-product-table-details__gift-label {
        font: $product-table-details-gift-label-font;
    }

    .c-product-table-details__gift-text {
        display: block;
        font: $product-table-details-gift-text-font;

        @include breakpoint(large) {
            font: $product-table-details-gift-text-font--large;
        }
    }

    .c-product-table-details__gift-message {
        font: $product-table-details-gift-message-font;
        color: $product-table-details-gift-message-color;
        word-break: $product-table-details-gift-message-word-break;

        @include breakpoint(large) {
            font: $product-table-details-gift-message-font--large;
            color: $product-table-details-gift-message-color--large;
        }
    }
}
