$account-table-cell-padding: rem-calc(3 10) !default;
$account-table-cell-padding--large: rem-calc(3 15) !default;
$account-table-cell-vertical-align: middle !default;
$account-table-cell-color: color(text-secondary) !default;
$account-table-cell-color--large: null !default;
$account-table-row-border-top: 1px solid color(border) !default;
$account-table-row-first-child-border-top: null !default;
$account-table-border-bottom: 1px solid color(border) !default;
$account-table-cell-height: rem-calc(55) !default;
$account-table-cell-height--large: null !default;
$account-table-cell-background: null !default;
$account-table-cell-background--large: null !default;
$account-table-cell-font: null !default;
$account-table-cell-font--large: null !default;
$account-table-cell-text-transform: null !default;
$account-table-header-box-shadow: null !default;
$account-table-header-box-shadow--large: null !default;

//Hover state
$account-table-cell-background-hover: color(element-background) !default;
$account-table-cell-color-hover: null !default;
$account-table-row-box-shadow-hover: 0 0 10px color(element-background) !default;
$account-table-cell-text-shadow-hover: none !default;

// Header
$account-table-cell-header-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$account-table-cell-header-font--large: null !default;
$account-table-cell-header-background: color(secondary) !default;
$account-table-cell-header-padding: rem-calc(3 10) !default;
$account-table-cell-header-padding--large: rem-calc(3 15) !default;
$account-table-cell-header-height: rem-calc(50) !default;
$account-table-cell-header-vertical-align: middle !default;
$account-table-cell-header-color: color(light) !default;
$account-table-cell-header-text-transform: none !default;
$account-table-cell-header-current-font-weight: null !default;

// Order icon
$account-table-cell-header-sort-icon: chevron2-down !default;
$account-table-cell-header-sort-icon-color: color(light) !default;
$account-table-cell-header-sort-icon-height: rem-calc(20) !default;
$account-table-cell-header-sort-icon-width: rem-calc(20) !default;
$account-table-cell-header-sort-icon-margin: rem-calc(0 0 0 5) !default;
$account-table-cell-header-sort-icon-right: rem-calc(5) !default;
$account-table-cell-header-sort-icon-usesvg: false !default;
$account-table-cell-header-sort-padding: rem-calc(3 30 3 10) !default;

/// Active
$account-table-cell-header-sort-active-icon: chevron2-top !default;
$account-table-cell-header-sort-active-icon-usesvg: false !default;
/// Current
$account-table-cell-header-sort-current-icon-color: color(secondary) !default;
$account-table-cell-header-sort-current-icon-background-color: color(element-background) !default;
$account-table-cell-header-sort-current-icon-border-radius: 50% !default;
$account-table-cell-header-sort-current-icon-height: null !default;
$account-table-cell-header-sort-current-icon-height--large: null !default;
$account-table-cell-header-sort-current-icon-width: null !default;
$account-table-cell-header-sort-current-icon-width--large: null !default;
$account-table-cell-header-sort-current-icon-background-size: 70% !default;
$account-table-cell-header-sort-current-font: null !default;
$account-table-cell-header-sort-current-font--large: null !default;
$account-table-cell-header-sort-current-color: null !default;
$account-table-cell-header-sort-icon-usesvg: false !default;

// Order details link
$account-table-link-details-font: #{rem-calc(14)} / 1.5 $font-primary !default;
$account-table-link-details-hover-font: bold #{rem-calc(14)} / 1.5 $font-primary !default;
$account-table-link-details-color: color(primary) !default;
$account-table-link-details-hover-color: $account-table-link-details-color !default;
$account-table-link-details-icon: chevron2-right !default;
$account-table-link-details-icon-height: rem-calc(12) !default;
$account-table-link-details-icon-width: rem-calc(7) !default;
$account-table-link-details-icon-usesvg: true !default;

// Specific styles
$account-table-cell-details-width: rem-calc(30) !default;
$account-table-cell-status-font: bold #{rem-calc(12)} / 1.5 $font-primary !default;
$account-table-cell-status-font--large: null !default;
$account-table-cell-status-color: null !default;
$account-table-cell-header-date-font: null !default;
$account-table-cell-header-date-font--large: null !default;
$account-table-cell-header-date-color: null !default;
$account-table-cell-product-font: null !default;
$account-table-cell-product-font--large: null !default;
$account-table-cell-product-color: null !default;
$account-table-cell-product-color--large: null !default;
$account-table-cell-date-font: null !default;
$account-table-cell-date-font--large: null !default;
$account-table-cell-date-color: null !default;
$account-table-cell-date-color--large: null !default;

@mixin lora-components-account-table {
    .c-account-table {
        border-collapse: collapse;
        width: 100%;
        border-bottom: $account-table-border-bottom;
    }

    .c-account-table__header {
        box-shadow: $account-table-header-box-shadow;

        @include breakpoint(large) {
            box-shadow: $account-table-header-box-shadow--large;
        }
    }

    // Header
    .c-account-table__cell-header {
        position: relative;
        background: $account-table-cell-header-background;
        color: $account-table-cell-header-color;
        font: $account-table-cell-header-font;
        height: $account-table-cell-header-height;
        padding: $account-table-cell-header-padding;
        vertical-align: $account-table-cell-header-vertical-align;
        text-transform: $account-table-cell-header-text-transform;

        @include breakpoint(large) {
            font: $account-table-cell-header-font--large;
            padding: $account-table-cell-header-padding--large;
        }

        &.m-date {
            font: $account-table-cell-header-date-font;
            color: $account-table-cell-header-date-color;

            @include breakpoint(large) {
                font: $account-table-cell-header-date-font--large;
            }
        }
    }

    .c-account-table__cell-header.m-sort {
        padding: $account-table-cell-header-sort-padding;

        @include breakpoint(large) {
            white-space: nowrap;
        }

        &::after {
            @include svg-icon($account-table-cell-header-sort-icon, $account-table-cell-header-sort-icon-color, 70%, center, no-repeat, $usesvg: $account-table-cell-header-sort-icon-usesvg);

            content: '';
            vertical-align: middle;
            border-radius: 50%;
            height: $account-table-cell-header-sort-icon-height;
            width: $account-table-cell-header-sort-icon-width;
            margin: $account-table-cell-header-sort-icon-margin;
            position: absolute;
            #{$global-right}: $account-table-cell-header-sort-icon-right;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }

        &.m-active {
            &::after {
                @include svg-icon($account-table-cell-header-sort-active-icon, $account-table-cell-header-sort-icon-color, 70%, center, no-repeat, $usesvg: $account-table-cell-header-sort-active-icon-usesvg);
            }
        }

        &.m-current {
            font: $account-table-cell-header-sort-current-font;
            font-weight: $account-table-cell-header-current-font-weight;
            color: $account-table-cell-header-sort-current-color;

            &::after {
                @include svg-icon($account-table-cell-header-sort-icon, $account-table-cell-header-sort-current-icon-color, $account-table-cell-header-sort-current-icon-background-size, center, no-repeat, $usesvg: $account-table-cell-header-sort-icon-usesvg);

                background-color: $account-table-cell-header-sort-current-icon-background-color;
                border-radius: $account-table-cell-header-sort-current-icon-border-radius;
                height: $account-table-cell-header-sort-current-icon-height;
                width: $account-table-cell-header-sort-current-icon-width;
            }

            @include breakpoint(large) {
                font: $account-table-cell-header-sort-current-font--large;

                &::after {
                    height: $account-table-cell-header-sort-current-icon-height--large;
                    width: $account-table-cell-header-sort-current-icon-width--large;
                }
            }
        }

        &.m-current.m-active {
            &::after {
                @include svg-icon($account-table-cell-header-sort-active-icon, $account-table-cell-header-sort-current-icon-color, $account-table-cell-header-sort-current-icon-background-size, center, no-repeat, $usesvg: $account-table-cell-header-sort-active-icon-usesvg);
            }
        }
    }

    // Rows
    .c-account-table__row {
        border-top: $account-table-row-border-top;

        &:first-child {
            border-top: $account-table-row-first-child-border-top;
        }
    }

    .c-account-table.m-hover .c-account-table__row {
        cursor: pointer;

        &:hover {
            box-shadow: $account-table-row-box-shadow-hover;

            .c-account-table__cell {
                background: $account-table-cell-background-hover;
                color: $account-table-cell-color-hover;
                text-shadow: $account-table-cell-text-shadow-hover;
            }
            @if ($account-table-link-details-color != $account-table-link-details-hover-color) {
                .c-account-table__link-details::after {
                    @include svg-icon($account-table-link-details-icon, $account-table-link-details-hover-color, 100%, left, no-repeat, $color-change: true, $usesvg: $account-table-link-details-icon-usesvg);
                }
            }
        }
    }

    // Cells
    .c-account-table__cell {
        height: $account-table-cell-height;
        vertical-align: $account-table-cell-vertical-align;
        background: $account-table-cell-background;

        @include breakpoint(large) {
            background: $account-table-cell-background--large;
            height: $account-table-cell-height--large;
        }
        @include text-style(
            $font: (
                small: $account-table-cell-font,
                large: $account-table-cell-font--large
            ),
            $color: (
                small: $account-table-cell-color,
                large: $account-table-cell-color--large
            ),
            $padding: (
                small: $account-table-cell-padding,
                large: $account-table-cell-padding--large
            ),
            $text-transform: (
                small: $account-table-cell-text-transform
            )
        );

        &.m-details {
            width: $account-table-cell-details-width;
        }

        &.m-status {
            font: $account-table-cell-status-font;
            color: $account-table-cell-status-color;

            @include breakpoint(large) {
                font: $account-table-cell-status-font--large;
            }
        }

        &.m-product {
            font: $account-table-cell-product-font;
            color: $account-table-cell-product-color;

            @include breakpoint(large) {
                font: $account-table-cell-product-font--large;
                color: $account-table-cell-product-color--large;
            }
        }

        &.m-date {
            font: $account-table-cell-date-font;
            color: $account-table-cell-date-color;

            @include breakpoint(large) {
                font: $account-table-cell-date-font--large;
                color: $account-table-cell-date-color--large;
            }
        }
    }

    // Hidden collumns on mobile
    .c-account-table__cell-header,
    .c-account-table__cell {
        &.m-number,
        &.m-ship-option,
        &.m-ship-number,
        &.m-price,
        &.m-frequency,
        &.m-delivered {
            @include hide-for(medium down);
        }
    }

    // Link to history details
    .c-account-table__link-details {
        font: $account-table-link-details-font;
        text-decoration: none;

        &::after {
            @include svg-icon($account-table-link-details-icon, $account-table-link-details-color, 100%, left, no-repeat, $usesvg: $account-table-link-details-icon-usesvg);

            content: '';
            display: block;
            height: $account-table-link-details-icon-height;
            width: $account-table-link-details-icon-width;
        }

        &:hover {
            color: $account-table-link-details-hover-color;
            font: $account-table-link-details-hover-font;
        }
    }

    // Tracking URL link
    .c-account-table__link-track {
        text-decoration: none;
        display: block;
    }

    .c-account-table__sort {
        cursor: pointer;
        position: absolute;
        width: 100%;
        // NGLORA-9904: [iOS fix] height 100% is not working in a table cell for absolute positioning
        height: $account-table-cell-header-height;
        left: 0;
        top: 0;
    }
}
