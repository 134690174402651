$account-prefilled-info-margin: rem-calc(0 0 25) !default;
$account-prefilled-info-margin--large: null !default;
$account-prefilled-info-max-width--large: none !default;
$account-prefilled-info-row-padding: rem-calc(10 0) !default;
$account-prefilled-info-row-margin: rem-calc(0 0 20) !default;
$account-prefilled-info-row-border-bottom: 1px solid color(border) !default;
$account-prefilled-info-label-margin: rem-calc(0 0 5) !default;
$account-prefilled-info-label-font: #{rem-calc(14)} / 1 $font-primary !default;
$account-prefilled-info-label-color: null !default;
$account-prefilled-info-label-text-transform: null !default;
$account-prefilled-info-label-letter-spacing: null !default;
$account-prefilled-info-value-font: #{rem-calc(18)} / 1 $font-primary !default;
$account-prefilled-info-value-font--large: #{rem-calc(24)} / 1 $font-primary !default;
$account-prefilled-info-value-letter-spacing: null !default;

@mixin lora-components-account-prefilled-info {
    .c-account__prefilled-info {
        margin: $account-prefilled-info-margin;

        @include breakpoint(large) {
            max-width: $account-prefilled-info-max-width--large;
            margin: $account-prefilled-info-margin--large;
        }
    }

    .c-account__prefilled-info-row {
        border-bottom: $account-prefilled-info-row-border-bottom;
        padding: $account-prefilled-info-row-padding;
        margin: $account-prefilled-info-row-margin;
    }

    .c-account__prefilled-info-label {
        display: inline-block;
        font: $account-prefilled-info-label-font;
        margin: $account-prefilled-info-label-margin;
        word-wrap: break-word;
        color: $account-prefilled-info-label-color;
        text-transform: $account-prefilled-info-label-text-transform;
        letter-spacing: $account-prefilled-info-label-letter-spacing;
    }

    .c-account__prefilled-info-value {
        font: $account-prefilled-info-value-font;
        word-break: break-all;
        letter-spacing: $account-prefilled-info-value-letter-spacing;

        @include breakpoint(large) {
            font: $account-prefilled-info-value-font--large;
        }
    }
}