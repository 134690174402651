// Deprecated components
@import "../../100-deprecated/account/account-box";

// Order Details Box
$order-details-box-margin: rem-calc(0 15 15) !default;
$order-details-box-margin--large: rem-calc(0 25 15) !default;

// Order Details Box title
$order-details-box-title-font: $account-box-title-font !default;
$order-details-box-title-text-transform: null !default;
$order-details-box-title-color: $account-box-title-color !default;
$order-details-box-title-background: $account-box-title-background !default;
$order-details-box-title-margin: rem-calc(0 -15 20) !default;
$order-details-box-title-margin--large: rem-calc(0 -25 20) !default;
$order-details-box-title-padding: $account-box-title-padding !default;
$order-details-box-title-padding--large: rem-calc(20 25) !default;
$order-details-box-title-text-align: null !default;
$order-details-box-title-text-align--large: null !default;
$order-details-box-title-border-width-large: null !default;
$order-details-box-title-border-style-large: null !default;
$order-details-box-title-border-color-large: null !default;
$order-details-box-title-letter-spacing: null !default;

// Order Details Box Section
$order-details-box-section-margin: rem-calc(0 0 30) !default;
$order-details-box-section-margin--large: null !default;

// Order Details Box Section Title
$order-details-box-section-title-margin: rem-calc(0 0 15) !default;
$order-details-box-section-title-font: #{rem-calc(18)} / 1 $font-primary !default;
$order-details-box-section-title-text-transform: uppercase !default;
$order-details-box-section-title-color: null !default;

// Order Details Box Sub Title
$order-details-box-sub-title-margin: $account-box-sub-title-margin !default;
$order-details-box-sub-title-font: $account-box-sub-title-font !default;
$order-details-box-sub-title-text-transform: $account-box-sub-title-text-transform !default;
$order-details-box-sub-title-color: null !default;

//Order Details Contacts
$order-details-box-delivery-summary-word-break: break-word !default;
$order-details-box-delivery-summary-letter-spacing: null !default;

// Order Details Box Secure Payment
$order-details-box-payment-info-margin: $account-box-payment-info-margin !default;
$order-details-box-payment-info-text-align: $account-box-payment-info-text-align !default;
$order-details-box-payment-info-color: null !default;
$order-details-box-payment-info-font-size: null !default;
$order-details-box-payment-info-font-weight: null !default;
$order-details-box-payment-info-text-transform: null !default;
$order-details-box-payment-info-letter-spacing: null !default;

@mixin lora-components-order-details-box {
    .c-order-details-box {
        margin: $order-details-box-margin;

        @include breakpoint(large) {
            margin: $order-details-box-margin--large; // we could have any content inside without care of last item margin
        }
    }

    .c-order-details-box__title {
        @include text-style(
            $font: (
                small: $order-details-box-title-font
            ),
            $margin: (
                small: $order-details-box-title-margin,
                large: $order-details-box-title-margin--large
            ),
            $padding: (
                small: $order-details-box-title-padding,
                large: $order-details-box-title-padding--large
            ),
            $text-align: (
                small: $order-details-box-title-text-align,
                large: $order-details-box-title-text-align--large
            ),
            $text-transform: (
                small: $order-details-box-title-text-transform
            ),
            $color: (
                small: $order-details-box-title-color
            ),
            $letter-spacing: (
                small: $order-details-box-title-letter-spacing
            )
        );

        background: $order-details-box-title-background;

        @include breakpoint(large) {
            border-width: $order-details-box-title-border-width-large;
            border-style: $order-details-box-title-border-style-large;
            border-color: $order-details-box-title-border-color-large;
        }
    }

    .c-order-details-box__section {
        margin: $order-details-box-section-margin;

        @include breakpoint(large) {
            margin: $order-details-box-section-margin--large; // we could have any content inside without care of last item margin
        }
    }

    .c-order-details-box__section-title {
        font: $order-details-box-section-title-font;
        margin: $order-details-box-section-title-margin;
        text-transform: $order-details-box-section-title-text-transform;
        color: $order-details-box-section-title-color;
    }

    .c-order-details-box__sub-title {
        font: $order-details-box-sub-title-font;
        margin: $order-details-box-sub-title-margin;
        text-transform: $order-details-box-sub-title-text-transform;
        color: $order-details-box-sub-title-color;
    }

    .c-order-details-box__delivery-summary {
        word-break: $order-details-box-delivery-summary-word-break;
        letter-spacing: $order-details-box-delivery-summary-letter-spacing;
    }

    .c-order-details-box__payment-info {
        list-style-type: none;
        margin: $order-details-box-payment-info-margin;
        text-align: $order-details-box-payment-info-text-align;
        color: $order-details-box-payment-info-color;
        font-size: $order-details-box-payment-info-font-size;
        font-weight: $order-details-box-payment-info-font-weight;
        text-transform: $order-details-box-payment-info-text-transform;
        letter-spacing: $order-details-box-payment-info-letter-spacing;
    }
}
