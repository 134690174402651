// Could be used by NGL-based brands before 4.0.0 release
// Variables migrated to 05-components/order/_order-details-box.scss

$account-box-margin: rem-calc(0 15 15) !default; // @new: $order-details-box-margin: $account-box-margin !default;
$account-box-margin--large: rem-calc(0 30 25) !default; // @new: $order-details-box-margin--large: $account-box-margin--large !default;

// Account Box title
$account-box-title-font: normal #{rem-calc(14)} / 1 $font-primary !default; // @new: $order-details-box-title-font: $account-box-title-font !default;
$account-box-title-text-transform: uppercase !default; // @new: $order-details-box-title-text-transform: $account-box-title-text-transform !default;
$account-box-title-color: color(light) !default; // @new: $order-details-box-title-color: $account-box-title-color !default;
$account-box-title-background: color(secondary) !default; // @new: $order-details-box-title-background: $account-box-title-background !default;
$account-box-title-margin: rem-calc(0 -15 15) !default; // @new: $order-details-box-title-margin: $account-box-title-margin !default;
$account-box-title-margin--large: rem-calc(0 -30 15) !default; // @new: $order-details-box-title-margin--large: $account-box-title-margin--large !default;
$account-box-title-padding: rem-calc(20 15) !default; // @new: $order-details-box-title-padding: $account-box-title-padding !default;
$account-box-title-text-align: center !default; // @new: $order-details-box-title-text-align: $account-box-title-text-align !default;
$account-box-title-text-align--large: center !default; // @new: $order-details-box-title-text-align--large: $account-box-title-text-align--large !default;

// Account Box Sub Title
$account-box-sub-title-margin: rem-calc(0 0 7) !default; // @new: $order-details-box-sub-title-margin: $account-box-sub-title-margin !default;
$account-box-sub-title-font: 500 #{rem-calc(14)} / 1 $font-primary !default; // @new: $order-details-box-sub-title-font: $account-box-sub-title-font !default;
$account-box-sub-title-text-transform: uppercase !default; // @new: $order-details-box-sub-title-text-transform: $account-box-sub-title-text-transform !default;

// Account Box Secure Payment
$account-box-payment-info-margin: rem-calc(0 0 15) !default; // @new: $order-details-box-payment-info-margin: $account-box-payment-info-margin !default;
$account-box-payment-info-text-align: $global-left !default; // @new: $order-details-box-payment-info-text-align: $account-box-payment-info-text-align !default;
