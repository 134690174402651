@import '../../05-components/product/product-grid';

$cart-samples-columns: 2 !default;
$cart-samples-columns--large: 6 !default;
$cart-samples-divider-enabled: false !default;
$cart-samples-grid-gap: rem-calc(15) !default;
$cart-samples-grid-gap--large: rem-calc(30) !default;
$cart-samples-grid-gap-row: rem-calc(10) !default;
$cart-samples-grid-gap-row-large: $cart-samples-grid-gap-row !default;
// Product grid
$cart-samples-product-grid-position: static !default;
$cart-samples-product-grid-tile-position: static !default;
$cart-samples-product-grid-tile-border: 1px solid color(transparent) !default;
$cart-samples-product-grid-tile-active-border-color: color(secondary-active) !default;
$cart-samples-product-grid-tile-active-border-color--large: null !default;
$cart-samples-product-grid-tile-transition: opacity 150ms ease-in-out;
$cart-samples-product-grid-tile-disabled-opacity: 0.3 !default;
$cart-samples-product-button-min-width: 0 !default;
// Product active button
$cart-samples-product-active-button-border: null !default;
$cart-samples-product-active-button-hover-border: null !default;
$cart-samples-product-active-button-background: null !default;
$cart-samples-product-active-button-hover-background: null !default;
$cart-samples-product-active-button-color: null !default;
$cart-samples-product-active-button-hover-color: null !default;
// Sorting
$cart-samples-sorting-margin: rem-calc(0 0 15) !default;
$cart-samples-sorting-margin--large: null !default;
// Tiles
$cart-samples-tile-padding: rem-calc(10) !default;
$cart-samples-tile-padding--large: null !default;
// Result block
$cart-samples-result-margin: rem-calc(0 0 20) !default;
$cart-samples-result-margin--large: rem-calc(0 0 40) !default;
// Result title
$cart-samples-result-title-color: color(text) !default;
$cart-samples-result-title-color--large: null !default;
$cart-samples-result-title-font: 400 #{rem-calc(18)} / 1 $font-primary !default;
$cart-samples-result-title-font--large: 400 #{rem-calc(18)} / 1 $font-primary !default;
$cart-samples-result-title-margin: rem-calc(0 0 20) !default;
$cart-samples-result-title-margin--large: rem-calc(0 0 20) !default;
$cart-samples-result-title-text-align: center !default;
$cart-samples-result-title-text-align--large: left !default;
$cart-samples-result-title-text-transform: uppercase !default;
$cart-samples-result-title-text-transform--large: null !default;
// Details
$cart-samples-details-padding: null !default;
$cart-samples-details-padding--large: rem-calc(40) !default;
$cart-samples-details-max-width: null !default;
$cart-samples-details-max-width--large: rem-calc(400) !default;
$cart-samples-details-flex-direction: column !default;
// Details content
$cart-samples-details-content-padding: rem-calc(0 16 16) !default;
$cart-samples-details-content-padding--large: rem-calc(0) !default;

@mixin lora-components-cart-samples {
    .c-cart-samples {
        @include lora-components-product-grid($cart-samples-columns, $cart-samples-columns--large, $cart-samples-divider-enabled, $cart-samples-grid-gap, $cart-samples-grid-gap--large, $cart-samples-grid-gap-row, $cart-samples-grid-gap-row-large);

        .c-product-grid {
            position: $cart-samples-product-grid-position;
        }

        /* stylelint-disable selector-max-compound-selectors */
        .c-product-grid .c-product-grid__tile {
            position: $cart-samples-product-grid-tile-position;
            border: $cart-samples-product-grid-tile-border;
            transition: $cart-samples-product-grid-tile-transition;
            padding: $cart-samples-tile-padding;

            @include breakpoint(large) {
                padding: $cart-samples-tile-padding--large;
            }

            &.m-active {
                border-color: $cart-samples-product-grid-tile-active-border-color;

                @include breakpoint(large) {
                    border-color: $cart-samples-product-grid-tile-active-border-color--large;
                }

                .c-product-tile__button {
                    @include lora-helper-button-style(
                        $border: $cart-samples-product-active-button-border,
                        $border-hover: $cart-samples-product-active-button-hover-border,
                        $background: $cart-samples-product-active-button-background,
                        $background-hover: $cart-samples-product-active-button-hover-background,
                        $color: $cart-samples-product-active-button-color,
                        $color-hover: $cart-samples-product-active-button-hover-color
                    );
                }
            }

            &.m-disabled {
                pointer-events: none;
                opacity: $cart-samples-product-grid-tile-disabled-opacity;
            }
        }
        /* stylelint-enable */

        .c-product-tile__button {
            min-width: $cart-samples-product-button-min-width;
        }
    }

    .c-cart-samples__result {
        margin: $cart-samples-result-margin;

        @include breakpoint(large) {
            margin: $cart-samples-result-margin--large;
        }
    }

    .c-cart-samples__result-title {
        @include text-style(
            $font: (
                small: $cart-samples-result-title-font,
                large: $cart-samples-result-title-font--large
            ),
            $color: (
                small: $cart-samples-result-title-color,
                large: $cart-samples-result-title-color--large
            ),
            $margin: (
                small: $cart-samples-result-title-margin,
                large: $cart-samples-result-title-margin--large
            ),
            $text-align: (
                small: $cart-samples-result-title-text-align,
                large: $cart-samples-result-title-text-align--large
            ),
            $text-transform: (
                small: $cart-samples-result-title-text-transform,
                large: $cart-samples-result-title-text-transform--large
            )
        );
    }

    .c-cart-samples__sorting {
        margin: $cart-samples-sorting-margin;

        @include breakpoint(large) {
            margin: $cart-samples-sorting-margin--large;
        }
    }

    .c-cart-samples__details {
        padding: $cart-samples-details-padding;
        max-width: $cart-samples-details-max-width;
        height: 100%;
        flex-direction: $cart-samples-details-flex-direction;

        @include breakpoint(large) {
            padding: $cart-samples-details-padding--large;
            max-width: $cart-samples-details-max-width--large;
        }
    }

    .c-cart-samples__details-content {
        padding: $cart-samples-details-content-padding;

        @include breakpoint(large) {
            padding: $cart-samples-details-content-padding--large;
        }
    }
}
