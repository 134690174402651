$account-orderlogisticstatus-background: rgb(255, 255, 255);
$account-orderlogisticstatus-padding: 1.25rem 1.5625rem;
$account-orderlogisticstatus-margin-bottom: 0 0 1.25rem;
$account-orderlogisticstatus-marker-number-padding: 0 0.6em;
$account-orderlogisticstatus-marker-number-padding-selected: 0 0.4em;
$account-orderlogisticstatus-height: 265px;
$account-orderlogisticstatus-step-height: 70px;
$account-orderlogisticstatus-border: 2px solid rgb(28, 48, 74);
$account-orderlogisticstatus-border-right: 1px solid rgb(28, 48, 74);
$account-orderlogisticstatus-font-black: #000;
$account-orderlogisticstatus-font-size: 2em;
$account-orderlogisticstatus-font-size-marker: 9px;
$account-orderlogisticstatus-font-size-step: 10px;
$account-orderlogisticstatus-top: 1em;
$account-orderlogisticstatus-margin-top: 20px;
$account-orderlogisticstatus-li-width: 1.9em;
$account-orderlogisticstatus-li-height: 1.7em;
$account-orderlogisticstatus-li-background: rgb(28, 48, 74);
$account-orderlogisticstatus-li-background-active: #04497b;
$account-orderlogisticstatus-li-marker: #7d7d7d;
$account-orderlogisticstatus-li-background-active-second: #b7b7b7;
$account-orderlogisticstatus-li-margin: 5em 1em;
$account-orderlogisticstatus-li-margin-marker: 0 0 0 40%;
$account-orderlogisticstatus-li-color: #fff;
$account-orderlogisticstatus-li-color-after: rgb(155, 155, 155);
$account-orderlogisticstatus-li-a: #434fff;
$account-orderlogisticstatus-z-index: 1;
$account-orderlogisticstatus-z-index-li: 2;
$account-orderlogisticstatus-margin: 10px;
$account-orderlogisticstatus-width: 70%;
$account-orderlogisticstatus-padding-top: 10px;
$account-orderlogisticstatus-landscape-top: 50%;
$account-orderlogisticstatus-landscape-left: -50%;
$account-orderlogisticstatus-landscape-status-top: 1.5em;
$account-orderlogisticstatus-landscape-status-top-link: 3.5em;
$account-orderlogisticstatus-landscape-date-top: -5em;
$account-orderlogisticstatus-landscape-time-top: -3em;
$account-orderlogisticstatus-portrait-margin: 15px;
$account-orderlogisticstatus-portrait-bulet-width: 0.8em;
$account-orderlogisticstatus-portrait-top: 3px;
$account-orderlogisticstatus-portrait-left: 27.5%;
$account-orderlogisticstatus-portrait-market-left: 30%;
$account-orderlogisticstatus-portrait-status-width: 48%;
$account-orderlogisticstatus-portrait-status-padding: 0 1.5em;
$account-orderlogisticstatus-portrait-date-width: 30%;
$account-orderlogisticstatus-portrait-time-padding: 2em;
$account-orderlogisticstatus-portrait-right-position: 30%;
$account-orderlogisticstatus-radius: 50%;
$account-orderlogisticstatus-bulet-height: 0.8em;
$account-orderlogisticstatus-bulet-landscape-top: -9px;
$account-orderlogisticstatus-bulet-landscape-left: 44%;

@mixin apaclayer-components-account-orderlogisticstatus {
    .orderlogisticstatus {
        background: $account-orderlogisticstatus-background;
        height: $account-orderlogisticstatus-height;
        width: 100%;
        display: block;
        padding: $account-orderlogisticstatus-padding;
        margin: $account-orderlogisticstatus-margin-bottom;
    }

    .orderlogisticstatus-title {
        font-weight: 300;
        font-size: $account-orderlogisticstatus-font-size;
        text-transform: uppercase;
        margin-bottom: $account-orderlogisticstatus-margin;
    }

    .orderlogisticstatus-content {
        margin-top: $account-orderlogisticstatus-margin;
        padding-top: $account-orderlogisticstatus-padding-top;
    }

    .l-account__order-details-content .l-account__order-details-main-content .c-order-info {
        display: none;
    }

    .copy-button-logistic-number {
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (orientation: landscape) {
        .orderlogisticstatus-content {
            width: $account-orderlogisticstatus-width;
        }

        .orderlogisticstatus-bar {
            text-align: justify;
            margin: $account-orderlogisticstatus-li-margin;
            list-style: none;
            position: relative;
            display: flex;
            justify-content: space-between;
        }

        .orderlogisticstatus-step {
            text-align: center;
            position: relative;
            // line-height: $account-orderlogisticstatus-li-height;
            font-size: $account-orderlogisticstatus-font-size-step;
            font-weight: bolder;
            width: 100%;

            &::before,
            &::after {
                content: "";
                height: $account-orderlogisticstatus-bulet-height;
                border-top: $account-orderlogisticstatus-border;
                border-bottom: $account-orderlogisticstatus-border;
                position: absolute;
                z-index: $account-orderlogisticstatus-z-index;
                width: 100%;
                left: $account-orderlogisticstatus-landscape-left;
                top: $account-orderlogisticstatus-landscape-top;
                transform: translateY(-50%);
                transition: all 0.25s ease-out;
            }

            &:first-child::before,
            &:first-child::after {
                display: none;
            }

            &::after {
                background-color: $account-orderlogisticstatus-li-background;
                width: 0%;
            }

            &.is-complete + &.is-current::after,
            &.is-complete + &.is-complete::after {
                width: 100%;
            }
        }

        .orderlogisticstatus-step .marker-number {
            // width: $account-orderlogisticstatus-li-width;
            // height: $account-orderlogisticstatus-li-height;
            text-align: center;
            border-top: $account-orderlogisticstatus-border;
            border-bottom: $account-orderlogisticstatus-border;
            border-radius: $account-orderlogisticstatus-radius;
            padding: $account-orderlogisticstatus-marker-number-padding;
            max-width: 100%;
            z-index: $account-orderlogisticstatus-z-index-li;
            position: absolute;
            top: $account-orderlogisticstatus-bulet-landscape-top;
            left: $account-orderlogisticstatus-bulet-landscape-left;
            transition: all 0.25s ease-out;
            color: $account-orderlogisticstatus-font-black;
            font-size: $account-orderlogisticstatus-font-size-marker;
            font-weight: 900;
            background-color: $account-orderlogisticstatus-background;
        }

        .is-current > .marker-number,
        .is-complete > .marker-number {
            color: $account-orderlogisticstatus-li-color;
            border: $account-orderlogisticstatus-border;
            background-color: $account-orderlogisticstatus-li-background;
            fill: $account-orderlogisticstatus-li-background;
            padding: $account-orderlogisticstatus-marker-number-padding-selected;
        }

        .orderlogisticstatus-bar li:last-child .marker-number {
            border-right: $account-orderlogisticstatus-border-right;
        }

        .logistic-status-value {
            display: block;
            text-transform: uppercase;
            color: $account-orderlogisticstatus-li-color-after;
            position: absolute;
            top: $account-orderlogisticstatus-landscape-status-top;
            width: 100%;
            transition: all 0.25s ease-out;

            .is-current > &,
            .is-complete > & {
                color: $account-orderlogisticstatus-font-black;
            }
        }

        .logistic-status-link {
            display: block;
            text-transform: uppercase;
            color: $account-orderlogisticstatus-li-color-after;
            position: absolute;
            top: $account-orderlogisticstatus-landscape-status-top-link;
            width: 100%;
            transition: all 0.25s ease-out;

            a {
                color: $account-orderlogisticstatus-li-a;
            }

            .is-current > &,
            .is-complete > & {
                color: $account-orderlogisticstatus-font-black;
            }
        }

        .logistic-date-value {
            display: block;
            text-transform: uppercase;
            color: $account-orderlogisticstatus-font-black;
            position: absolute;
            top: $account-orderlogisticstatus-landscape-date-top;
            width: 100%;
            transition: all 0.25s ease-out;
        }

        .logistic-time-value {
            display: block;
            text-transform: uppercase;
            color: $account-orderlogisticstatus-font-black;
            position: absolute;
            top: $account-orderlogisticstatus-landscape-time-top;
            width: 100%;
            transition: all 0.25s ease-out;
        }

        .orderlogisticstatus-viewfull-hidden,
        .orderlogisticstatus-viewfull-hidden + label {
            display: none;
        }
    }

    @media screen and (orientation: portrait) {
        .orderlogisticstatus {
            height: fit-content;
            margin-top: $account-orderlogisticstatus-portrait-margin;
        }

        .orderlogisticstatus-bar {
            display: contents;
            box-sizing: border-box;
        }

        .orderlogisticstatus span {
            margin-bottom: $account-orderlogisticstatus-margin;
            display: block;
            font-weight: bolder;
        }

        .orderlogisticstatus-viewfull-hidden {
            display: none;
        }

        .orderlogisticstatus-viewfull-hidden + label {
            margin-top: $account-orderlogisticstatus-portrait-margin;
        }

        .orderlogisticstatus-step {
            text-align: center;
            display: flex;
            line-height: $account-orderlogisticstatus-li-height;
            font-size: $account-orderlogisticstatus-font-size-marker;
            width: 100%;
            height: $account-orderlogisticstatus-step-height;

            &::before {
                content: "";
                border-top: 0;
                border-bottom: 0;
                border-left: $account-orderlogisticstatus-border;
                border-right: $account-orderlogisticstatus-border;
                position: relative;
                z-index: $account-orderlogisticstatus-z-index;
                width: $account-orderlogisticstatus-portrait-bulet-width;
                left: $account-orderlogisticstatus-portrait-left;
                top: $account-orderlogisticstatus-portrait-top;
                transform: translateX(50%);
                transition: all 0.25s ease-out;
            }

            &:last-child {
                height: fit-content;
            }

            &:last-child::before,
            &:last-child::after {
                display: none;
            }

            &.is-complete::before {
                background-color: $account-orderlogisticstatus-li-background;
            }
        }

        .orderlogisticstatus-step .marker-number {
            width: $account-orderlogisticstatus-li-width;
            // height: $account-orderlogisticstatus-li-height;
            text-align: center;
            border-left: $account-orderlogisticstatus-border;
            border-right: $account-orderlogisticstatus-border;
            border-radius: $account-orderlogisticstatus-radius;
            padding: 0;
            max-width: 100%;
            z-index: $account-orderlogisticstatus-z-index-li;
            position: absolute;
            left: $account-orderlogisticstatus-portrait-market-left;
            transition: all 0.25s ease-out;
            color: $account-orderlogisticstatus-font-black;
            font-size: $account-orderlogisticstatus-font-size-marker;
            background-color: $account-orderlogisticstatus-background;
        }

        .is-current > .marker-number,
        .is-complete > .marker-number {
            color: $account-orderlogisticstatus-li-color;
            background-color: $account-orderlogisticstatus-li-background;
            fill: $account-orderlogisticstatus-li-background;
            padding: 0;
        }

        .orderlogisticstatus-bar li:last-child .marker-number {
            border-bottom: $account-orderlogisticstatus-border-right;
        }

        .logistic-status-value {
            display: inline-block;
            color: $account-orderlogisticstatus-li-color-after;
            position: relative;
            padding: $account-orderlogisticstatus-portrait-status-padding;
            transition: all 0.25s ease-out;
            width: $account-orderlogisticstatus-portrait-status-width;

            .is-current > &,
            .is-complete > & {
                color: $account-orderlogisticstatus-font-black;
            }
        }

        .logistic-status-link {
            text-transform: uppercase;
            color: $account-orderlogisticstatus-font-black;
            position: absolute;
            transition: all 0.25s ease-out;
            right: $account-orderlogisticstatus-portrait-right-position;
            width: $account-orderlogisticstatus-portrait-date-width;
            padding-top: $account-orderlogisticstatus-portrait-time-padding;

            a {
                color: $account-orderlogisticstatus-li-a;
            }

            .is-current > &,
            .is-complete > & {
                color: $account-orderlogisticstatus-font-black;
            }
        }

        .logistic-date-value {
            text-transform: uppercase;
            color: $account-orderlogisticstatus-font-black;
            position: relative;
            transition: all 0.25s ease-out;
            width: $account-orderlogisticstatus-portrait-date-width;
        }

        .logistic-time-value {
            text-transform: uppercase;
            color: $account-orderlogisticstatus-font-black;
            position: absolute;
            transition: all 0.25s ease-out;
            width: $account-orderlogisticstatus-portrait-date-width;
            padding-top: $account-orderlogisticstatus-portrait-time-padding;
        }

        .orderlogisticstatus-step-hidden {
            display: none;
        }
    }
}
