$product-quantity-text-align: center !default;
$product-quantity-secondary-medium-down: rem-calc(0 0 0 90) !default;

@mixin lora-components-product-quantity {
    .c-product-quantity {
        text-align: $product-quantity-text-align;
    }

    .c-product-quantity__wrapper {
        @include breakpoint(medium down) {
            flex: 0 0 50%;

            .c-product-quantity {
                @include breakpoint(medium down) {
                    text-align: $global-left;
                    padding: $product-quantity-secondary-medium-down;
                    line-height: 1;
                }
            }
        }
    }
}
