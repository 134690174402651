$cart-summary-table-title-font: 500 #{rem-calc(24)} / 1 $font-primary !default;
$cart-summary-table-item-presentation-height: rem-calc(18) !default;
$cart-summary-table-title-text-transform: uppercase !default;
$cart-summary-table-font-size: rem-calc(12) !default;
$cart-summary-table-letter-spacing: null !default;
$cart-summary-table-cell-padding: rem-calc(6 0) !default;
$cart-summary-table-label-font-family: $font-primary !default;
$cart-summary-table-label-font-weight: normal !default;
$cart-summary-table-label-font: $cart-summary-table-label-font-weight #{rem-calc(14)} / 1.5 $cart-summary-table-label-font-family !default;
$cart-summary-table-label-color: null !default;
$cart-summary-table-label-text-transform: none !default;
$cart-summary-table-label-text-align: $global-left !default;
$cart-summary-table-label-letter-spacing: null !default;
$cart-summary-table-value-text-align: $global-right !default;
$cart-summary-table-value-vertical-align: top !default;
$cart-summary-table-value-font-weight: normal !default;
$cart-summary-table-value-font-family: $font-primary !default;
$cart-summary-table-value-font: $cart-summary-table-value-font-weight #{rem-calc(14)} / 1.5 $cart-summary-table-value-font-family !default;
$cart-summary-table-value-letter-spacing: null !default;
$cart-summary-table-gift-certificate-font-weight: normal !default;
$cart-summary-table-estimate-button-color: color(text) !default;
$cart-summary-table-estimate-button-color-hover: color(primary-active) !default;
$cart-summary-table-estimate-button-text-decoration: underline !default;
$cart-summary-table-estimate-button-text-decoration-hover: underline !default;
$cart-summary-table-estimate-cell-value-margin: rem-calc(0 0 0 30) !default;
$cart-summary-table-estimate-form-padding: rem-calc(10 0) !default;
$cart-summary-table-estimate-button-font-family: $font-primary !default;
$cart-summary-table-estimate-button-font-weight: null !default;
$cart-summary-table-estimate-button-text-transform: null !default;
$cart-summary-table-cell-total-font: 500 #{rem-calc(14)} / 1.2 $font-primary !default;
$cart-summary-table-cell-total-value-font: 700 #{rem-calc(14)} / 1.2 $font-primary !default;
$cart-summary-table-cell-total-text-transform: none !default;
$cart-summary-table-item-total-border-top: none !default;
$cart-summary-table-border-collapse: null !default;
$cart-summary-table-border-spacing: 0 !default;
$cart-summary-table-cell-total-padding: null !default;
$cart-summary-table-cell-total-color: null !default;
$cart-summary-table-value-color: null !default;
$cart-summary-table-label-white-space: nowrap !default;

@mixin lora-components-cart-summary-table {
    .c-cart-summary-table {
        font-size: $cart-summary-table-font-size;
        table-layout: fixed;
        width: 100%;
        border-collapse: $cart-summary-table-border-collapse;
        border-spacing: $cart-summary-table-border-spacing;
        letter-spacing: $cart-summary-table-letter-spacing;
    }

    .c-cart-summary-table__item[role="presentation"] {
        height: $cart-summary-table-item-presentation-height;
    }

    .c-cart-summary-table__cell {
        padding: $cart-summary-table-cell-padding;

        &.m-label {
            font: $cart-summary-table-label-font;
            color: $cart-summary-table-label-color;
            text-align: $cart-summary-table-label-text-align;
            text-transform: $cart-summary-table-label-text-transform;
            white-space: $cart-summary-table-label-white-space;
            letter-spacing: $cart-summary-table-label-letter-spacing;
        }

        &.m-value {
            text-align: $cart-summary-table-value-text-align;
            vertical-align: $cart-summary-table-value-vertical-align;
            font: $cart-summary-table-value-font;
            color: $cart-summary-table-value-color;
            letter-spacing: $cart-summary-table-value-letter-spacing;
        }
    }

    .c-cart-summary-table__gift-certificate {
        display: block;
        font-weight: $cart-summary-table-gift-certificate-font-weight;
    }

    .c-cart-summary-table__estimate {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .c-cart-summary-table__estimate-button {
        color: $cart-summary-table-estimate-button-color;
        cursor: pointer;
        text-decoration: $cart-summary-table-estimate-button-text-decoration;
        font-family: $cart-summary-table-estimate-button-font-family;
        font-weight: $cart-summary-table-estimate-button-font-weight;
        text-transform: $cart-summary-table-estimate-button-text-transform;

        &:hover,
        &:focus {
            color: $cart-summary-table-estimate-button-color-hover;
            @if $cart-summary-table-estimate-button-text-decoration-hover != $cart-summary-table-estimate-button-text-decoration {
                text-decoration: $cart-summary-table-estimate-button-text-decoration-hover;
            }
        }
    }

    .c-cart-summary-table__estimate-form {
        display: none;
        padding: $cart-summary-table-estimate-form-padding;

        .c-cart-summary-table__estimate-input-field {
            text-align: $global-left;
        }
    }

    .c-cart-summary-table__item {
        &.m-active {
            .c-cart-summary-table__estimate-form {
                display: flex;
                width: 100%;
            }

            .c-cart-summary-table__cell.m-value {
                margin: $cart-summary-table-estimate-cell-value-margin;
            }
        }

        &.m-estimated {
            .c-cart-summary-table__estimate-button {
                display: inline-block;
            }

            .c-cart-summary-table__cell.m-label {
                line-height: 1;
            }

            .c-cart-summary-table__estimate {
                flex-wrap: wrap;
            }

            .c-cart-summary-table__re-estimate {
                width: 100%;
            }
        }

        &.m-total {
            border-top: $cart-summary-table-item-total-border-top;

            .c-cart-summary-table__cell {
                font: $cart-summary-table-cell-total-font;
                text-transform: $cart-summary-table-cell-total-text-transform;
                padding: $cart-summary-table-cell-total-padding;
                color: $cart-summary-table-cell-total-color;
            }

            .c-cart-summary-table__cell.m-value {
                font: $cart-summary-table-cell-total-value-font;
            }
        }
    }
}
