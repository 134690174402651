/*------------------------------------*\
        #MY ACCOUNT PAGE
\*------------------------------------*/
@import "../05-components/button";
@import "../05-components/button-group";
@import "../05-components/accordion";

// Main My Account
$account-padding: rem-calc(0 0 20) !default;
$account-padding--large: rem-calc(0 0 50) !default;
$account-loginregistration-margin-top--large: rem-calc(0) !default;
$account-loginregistration-justify-content--large: null !default;
$account-content-padding-top--large: rem-calc(15) !default;
$account-content-statuses-tag-margin: rem-calc(5 10 10 5) !default;
$account-secondary-background: color(global-background) !default;
$account-secondary-background--large: null !default;
$account-header-margin--large: 0 !default;
$account-header-actions-margin--medium-down: rem-calc(0 0 15) !default;
$account-header-actions-spacing: rem-calc(10) !default;
$account-header-actions-spacing--medium-down: $account-header-actions-spacing !default;
$account-header-order-details-padding: rem-calc(0 15) !default;
$account-header-order-details-padding--large: rem-calc(0) !default;
$account-header-subscription-details-padding: $account-header-order-details-padding !default;
$account-header-subscription-details-padding--large: $account-header-order-details-padding--large !default;
$account-footer-actions-margin: rem-calc(20 0) !default;
$account-pagination-padding: rem-calc(30 0);
$account-secondary-padding: null !default;
$account-secondary-padding--large: null !default;

// Breadcrumbs
$account-main-top-border-bottom: 1px solid color(border) !default;
$account-main-top-margin: rem-calc(0 0 25) !default;
$account-main-top-padding: rem-calc(10 0) !default;
$account-main-top-secondary-margin: rem-calc(0 0 15) !default;

// Back
$account-back-margin: rem-calc(0 15 20) !default;
$account-back-margin--large: rem-calc(0 0 20) !default;

// Title, Subtitle, User name
$account-secondary-title-margin: rem-calc(0 0 10) !default;
$account-secondary-title-margin--large: rem-calc(0 0 30) !default;

// Sidebar and content
$account-sidebar-columns--large: 4 of 12 !default;
$account-content-columns--large: 8 of 12 !default;

// Login and Registration page
$account-login-content-columns--large: 5 of 12 !default;
$account-registration-content-columns--large: 7 of 12 !default;

// Account Section
$account-section-gutters: rem-calc(15) !default;
$account-section-gutters--large: rem-calc(35) !default;
$account-section-background: color(light) !default;
$account-section-border: null !default;
$account-section-padding: rem-calc(15 $account-section-gutters) !default;
$account-section-padding--large: rem-calc(30 $account-section-gutters--large) !default;
$account-section-margin: rem-calc(0 -15 10) !default;
$account-section-margin--large: rem-calc(0 0 20) !default;
$account-section-default-margin: rem-calc(0 -15 2) !default;
$account-section-default-margin--large: rem-calc(0 0 10) !default;
$account-section-secondary-padding: rem-calc(0) !default;
$account-section-secondary-margin--large: rem-calc(0) !default;

$account-section-item-margin: rem-calc(0 (-$account-section-gutters) 20) !default;
$account-section-item-margin--large: rem-calc(0 (-$account-section-gutters--large) 20) !default;

// Order details
$account-order-details-aside-background--large: color(light) !default;
$account-order-details-main-width--large: 75% !default;
$account-order-details-aside-width--large: 25% !default;
$account-order-details-aside-padding--large: rem-calc(0 0 0 30) !default;

// Account Main Col
$account-main-col-padding--medium-down: rem-calc(25 15) !default;
$account-main-col-border-top--medium-down: 1px solid color(border) !default;

// Account Main Col small
$account-main-col-small-columns--large: 4 of 12 !default;

// Account Main Col medium
$account-main-col-medium-columns--large: 6 of 12 !default;

@mixin lora-layout-account {
    .l-account {
        padding: $account-padding;

        @include breakpoint(large) {
            padding: $account-padding--large;
        }

        &.m-secondary {
            background: $account-secondary-background;
            padding: $account-secondary-padding;

            @include breakpoint(large) {
                background: $account-secondary-background--large;
                padding: $account-secondary-padding--large;
            }

            .c-account__title {
                margin: $account-secondary-title-margin;

                @include breakpoint(large) {
                    margin: $account-secondary-title-margin--large;
                }
            }
        }
    }

    .l-account__main {
        @include breakpoint(large) {
            @include layout;
        }
    }

    .l-account__main-top {
        border-bottom: $account-main-top-border-bottom;
        display: none;
        margin: $account-main-top-margin;
        padding: $account-main-top-padding;

        @include breakpoint(large) {
            display: block;
        }

        &.m-secondary {
            margin: $account-main-top-secondary-margin;
        }
    }

    .l-account__pagination {
        padding: $account-pagination-padding;

        &:empty {
            visibility: hidden;
            padding: 0;
        }
    }

    .l-account__loginregistration {
        @include breakpoint(large) {
            @include grid;

            justify-content: $account-loginregistration-justify-content--large;
            margin-top: $account-loginregistration-margin-top--large;
        }

        .c-account__title {
            @include breakpoint(medium down) {
                display: none;
            }
        }

        .l-account__registration-content {
            @include breakpoint(large) {
                display: block;
            }
        }
    }

    .l-account__login-content {
        @include cell;

        @include breakpoint(large) {
            @include cell($account-login-content-columns--large);
        }
    }

    .l-account__registration-content {
        @include cell;

        @include breakpoint(large) {
            @include cell($account-registration-content-columns--large);
        }
    }

    .l-account__header {
        @include breakpoint(large) {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin: $account-header-margin--large;
        }

        &.m-order-details {
            padding: $account-header-order-details-padding;

            @include breakpoint(large) {
                align-items: baseline;
                padding: $account-header-order-details-padding--large;
            }
        }

        &.m-subscription-details {
            padding: $account-header-subscription-details-padding;

            @include breakpoint(large) {
                display: block;
                padding: $account-header-subscription-details-padding--large;
            }
        }
    }

    .l-account__header-actions {
        @include lora-helper-button-group('.l-account__header-actions-item', $account-header-actions-spacing);

        @include breakpoint(medium down) {
            @include lora-helper-button-group-expand('.l-account__header-actions-item', $account-header-actions-spacing--medium-down);

            margin: $account-header-actions-margin--medium-down;
        }
    }

    .l-account__footer-actions {
        @include hide-for(large);

        margin: $account-footer-actions-margin;
    }

    .l-account__main-wrapper {
        @include breakpoint(large) {
            @include grid;

            margin-top: $account-loginregistration-margin-top--large;
        }
    }

    .l-account__sidebar {
        @include cell;

        @include breakpoint(large) {
            @include cell($account-sidebar-columns--large);
        }
    }

    .l-account__content {
        @include cell;

        @include breakpoint(large) {
            padding-top: $account-content-padding-top--large;
        }

        .c-account__statuses .c-tag {
            margin: $account-content-statuses-tag-margin;
        }
    }

    .l-account__content:not(.m-full) {
        @include breakpoint(large) {
            @include cell($account-content-columns--large);
        }
    }

    .l-account__back {
        margin: $account-back-margin;

        @include breakpoint(large) {
            margin: $account-back-margin--large;
        }
    }

    .l-account__order-details-content {
        @include breakpoint(large) {
            align-items: flex-start;
            display: flex;
        }

        &.c-tabs__content {
            margin: 0;
        }
    }

    .l-account__order-details-main-content.c-tabs__panel {
        @include breakpoint(large) {
            display: block;
            order: 1;
            width: $account-order-details-main-width--large;
        }

        .c-order-info {
            margin: 0;
        }
    }

    .l-account__order-details-aside-content.c-tabs__panel {
        @include breakpoint(large) {
            display: block;
            order: 2;
            padding: $account-order-details-aside-padding--large;
            width: $account-order-details-aside-width--large;
        }

        .c-product-box {
            background: $account-order-details-aside-background--large;
        }
    }

    .l-account__section {
        background: $account-section-background;
        margin: $account-section-margin;
        padding: $account-section-padding;

        @include breakpoint(large) {
            margin: $account-section-margin--large;
            padding: $account-section-padding--large;
        }

        &:not(:last-child) {
            border-bottom: $account-section-border;
        }

        &:empty {
            display: none;
        }

        &.m-secondary {
            padding: $account-section-secondary-padding;

            @include breakpoint(large) {
                margin: $account-section-secondary-margin--large;
            }
        }

        &.m-default {
            margin: $account-section-default-margin;

            @include breakpoint(large) {
                margin: $account-section-default-margin--large;
            }
        }
    }

    .l-account__section-item {
        margin: $account-section-item-margin;

        @include breakpoint(large) {
            margin: $account-section-item-margin--large;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .l-account__main-col {
        @include cell;

        @include breakpoint(medium down) {
            padding: $account-main-col-padding--medium-down;
            border-top: $account-main-col-border-top--medium-down;
        }

        &.m-small {
            @include breakpoint(large) {
                @include cell($account-main-col-small-columns--large);
            }
        }

        &.m-medium {
            @include breakpoint(large) {
                @include cell($account-main-col-medium-columns--large);
            }
        }
    }
}
