$account-offline-orders-table-header-padding: rem-calc(20) !default;
$account-offline-orders-cell-header-color: color(light) !default;
$account-offline-orders-cell-header-background: color(dark) !default;
$account-offline-orders-cell-background: none !default;
$account-offline-orders-table-header-text-padding: rem-calc(0 0 10) !default;
$account-offline-orders-table-value-padding: rem-calc(0 0 10) !default;

@mixin apaclayer-components-account-offline-orders {
    .c-account-orders-table {
        border: none;

        &.m-header-small {
            @include hide-for(large);
        }

        &.m-header {
            @include show-for(large);
        }

        .c-account-orders-table__header-text {
            padding: $account-offline-orders-table-header-text-padding;
        }

        .c-account-orders-table__value {
            padding: $account-offline-orders-table-value-padding;
        }
    }

    .c-account-orders-table__header {
        &.m-header {
            @include show-for(large);
        }

        &.m-header-small {
            @include hide-for(large);
        }

        &.m-header-text {
            @include show-for(large);

            padding: $account-offline-orders-table-header-padding;
        }
    }

    .c-account-table__cell-header {
        background: $account-offline-orders-cell-header-background;
        color: $account-offline-orders-cell-header-color;
    }

    .c-account-table__cell {
        background: $account-offline-orders-cell-background;
    }
}
