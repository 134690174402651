// Deprecated components
@import "../../100-deprecated/account/account";

$account-info-margin: rem-calc(0 0 20) !default;
$account-info-margin--large: rem-calc(0 0 20) !default;
$account-info-font: null !default;
$account-info-color: null !default;
$account-info-letter-spacing: null !default;
$account-info-text-transform: null !default;
$account-info-starred-font: 500 #{rem-calc(14)} / 1 $font-primary !default;
$account-info-starred-margin: rem-calc(0 10 5 0) !default;
$account-info-starred-display: null !default;
$account-info-starred-text-transform: null !default;
$account-info-starred-color: null !default;

// Titles
$account-title-color: null !default;
$account-title-color--large: null !default;
$account-title-font: #{rem-calc(24)} / 1.4 $font-primary !default;
$account-title-font--large: #{rem-calc(30)} / 1.4 $font-primary !default;
$account-title-margin: rem-calc(0 0 10) !default;
$account-title-text-transform: uppercase !default;
$account-title-text-align: null !default;
$account-title-text-align--large: null !default;
$account-subtitle-font: #{rem-calc(18)} / 1 $font-primary !default;
$account-subtitle-font--large: null !default;
$account-subtitle-letter-spacing: null !default;
$account-subtitle-text-transform: uppercase !default;
$account-subtitle-margin: rem-calc(0 0 20) !default;
$account-subtitle-margin--large: null !default;
$account-subtitle-color: null !default;
$account-subtitle-color--large: null !default;
$account-sidebar-title-margin: rem-calc(0 0 15) !default;
$account-sidebar-title-font: #{rem-calc(14)} / 1 $font-primary !default;
$account-sidebar-title-text-transform: uppercase !default;
$account-sidebar-title-text-color: null !default;
$account-sidebar-title-letter-spacing: null !default;
$account-navigation-title-text-transform: uppercase !default;
$account-navigation-title-margin: rem-calc(0 0 20) !default;
$account-navigation-title-font: #{rem-calc(24)} / 1 $font-primary !default;
$account-navigation-title-font--large: #{rem-calc(18)} / 1 $font-primary !default;
// Username
$account-user-name-margin: rem-calc(0 0 30) !default;
$account-user-name-font: 500 #{rem-calc(18)} / 1 $font-primary !default;
$account-user-name-text-transform: null !default;
// Wishlist
$account-wishlist-margin--large: null !default;
$account-wishlist-header-margin--medium-down: rem-calc(0 0 20 0) !default;
$account-title-wishlist-padding: rem-calc(30 0 20) !default;
$account-title-wishlist-padding--large: rem-calc(30 0 0 0) !default;
$account-wishlist-share-button-margin: rem-calc(15 0 0) !default;

$account-content-section-background: color(light) !default;
$account-content-section-background--large: null !default;
$account-content-section-margin: rem-calc(0 0 20) !default;
$account-content-section-margin--large: null !default;

@mixin lora-components-account {
    .c-account__info {
        margin: $account-info-margin;
        font: $account-info-font;
        color: $account-info-color;
        text-transform: $account-info-text-transform;
        letter-spacing: $account-info-letter-spacing;

        @include breakpoint(large) {
            margin: $account-info-margin--large;
        }
    }

    .c-account__info-starred {
        font: $account-info-starred-font;
        margin: $account-info-starred-margin;
        display: $account-info-starred-display;
        text-transform: $account-info-starred-text-transform;
        color: $account-info-starred-color;

        @include breakpoint(medium down) {
            display: block;
        }
    }

    .c-account__title {
        @include text-style(
            $font: (
                small: $account-title-font,
                large: $account-title-font--large
            ),
            $color: (
                small: $account-title-color,
                large: $account-title-color--large
            ),
            $margin: (
                small: $account-title-margin
            ),
            $text-align: (
                small: $account-title-text-align,
                large: $account-title-text-align--large
            ),
            $text-transform: (
                small: $account-title-text-transform
            )
        );
    }

    .c-account__title-wishlist {
        padding: $account-title-wishlist-padding;

        @include breakpoint(large) {
            padding: $account-title-wishlist-padding--large;
        }
    }

    .c-account__subtitle {
        @include text-style(
            $font: (
                small: $account-subtitle-font,
                large: $account-subtitle-font--large
            ),
            $color: (
                small: $account-subtitle-color,
                large: $account-subtitle-color--large
            ),
            $margin: (
                small: $account-subtitle-margin,
                large: $account-subtitle-margin--large
            ),
            $text-transform: (
                small: $account-subtitle-text-transform
            ),
            $letter-spacing: (
                small: $account-subtitle-letter-spacing
            )
        );
    }

    .c-account__sidebar-title {
        @include show-for(large);

        font: $account-sidebar-title-font;
        margin: $account-sidebar-title-margin;
        text-transform: $account-sidebar-title-text-transform;
        color: $account-sidebar-title-text-color;
        letter-spacing: $account-sidebar-title-letter-spacing;
    }

    .c-account__user-name {
        display: inline-block;
        font: $account-user-name-font;
        margin: $account-user-name-margin;
        text-transform: $account-user-name-text-transform;
    }

    .c-account__navigation-title {
        font: $account-navigation-title-font;
        margin: $account-navigation-title-margin;
        text-transform: $account-navigation-title-text-transform;

        @include breakpoint(large) {
            font: $account-navigation-title-font--large;
        }
    }

    .c-account__wishlist {
        @include breakpoint(large) {
            margin: $account-wishlist-margin--large;
        }
    }

    .c-account__wishlist-header {
        @include breakpoint(medium down) {
            flex-flow: column;
            margin: $account-wishlist-header-margin--medium-down;
        }
    }

    .c-account__wishlist-share-button {
        margin: $account-wishlist-share-button-margin;
    }

    .c-account__content-section {
        background: $account-content-section-background;
        margin: $account-content-section-margin;

        @include breakpoint(large) {
            background: $account-content-section-background--large;
            margin: $account-content-section-margin--large;
        }
    }
}
