// Deprecated components
@import "../../100-deprecated/product/product-table";
@import "../icon";

$product-table-column-header-background: color(secondary) !default;
$product-table-column-header-color: color(light) !default;
$product-table-column-header-font: normal #{rem-calc(14)} / 1 $font-primary !default;
$product-table-column-header-padding: rem-calc(20) !default;
$product-table-column-header-margin--medium-down: rem-calc(0 -15) !default;
$product-table-column-header-price-text-align--large: center !default;
$product-table-column-header-text-transform: null !default;
$product-table-column-header-letter-spacing: null !default;
$product-table-row-product-padding--medium-down: rem-calc(10 0) !default;
$product-table-row-product-border-top--large: none !default;
$product-table-row-product-border-bottom--medium-down: none !default;
$product-table-row-product-grid-template-columns--medium-down: rem-calc(90) auto !default;
$product-table-row-product-no-image-grid-template-columns--medium-down: auto !default;
$product-table-row-giftcert-quantity-value-padding: rem-calc(14 15) !default;
$product-table-row-giftcert-quantity-value-background: color(element-background) !default;
$product-table-row-giftcert-quantity-value-color: color(light) !default;
$product-table-cell-padding: rem-calc(20 15 10) !default;
$product-table-cell-padding--medium-down: rem-calc(5 0 5 10) !default;
$product-table-cell-image-padding: rem-calc(20 15 20 0) !default;
$product-table-cell-image-padding--medium-down: rem-calc(5 10 5 0) !default;
$product-table-cell-image-grid-column--medium-down: 1 !default;
$product-table-cell-image-grid-row--medium-down: 1 / span 3 !default;
$product-table-cell-image-width: rem-calc(90) !default;
$product-table-cell-image-width--large: rem-calc(165) !default;
$product-table-cell-image-border-top--medium-down: none !default;
$product-table-cell-image-border-bottom--medium-down: none !default;
$product-table-cell-details-grid-column--medium-down: 2 !default;
$product-table-cell-details-grid-row--medium-down: 1 !default;
$product-table-cell-actions-grid-column--medium-down: 2 !default;
$product-table-cell-actions-no-image-grid-column--medium-down: 1 !default;
$product-table-cell-actions-grid-row--medium-down: 4 !default;
$product-table-cell-actions-padding: rem-calc(0 15 20) !default;
$product-table-cell-actions-padding--medium-down: rem-calc(0 0 5 10) !default;
$product-table-secondary-cell-gift-actions-grid-column-start--medium-down: 3 !default;
$product-table-secondary-cell-gift-actions-grid-column-end--medium-down: 1 !default;
$product-table-cell-details-width--large: auto !default;
$product-table-cell-details-border-top--medium-down: none !default;
$product-table-cell-details-border-bottom--medium-down: none !default;
$product-table-cell-details-no-image-grid-column--medium-down: $product-table-cell-actions-no-image-grid-column--medium-down !default;
$product-table-cell-details-padding--large: null !default;
$product-table-cell-details-padding--medium-down: null !default;
$product-table-cell-quantity-padding--medium-down: null !default;
$product-table-cell-quantity-padding--large: null !default;
$product-table-cell-quantity-font: null !default;
$product-table-cell-quantity-font--large: null !default;
$product-table-cell-quantity-color: null !default;
$product-table-cell-quantity-margin: 0 !default;
$product-table-cell-quantity-width--large: rem-calc(130) !default;
$product-table-cell-quantity-no-image-grid-column--medium-down: $product-table-cell-actions-no-image-grid-column--medium-down !default;
$product-table-cell-total-width--large: rem-calc(95) !default;
$product-table-cell-quantity-grid-column--medium-down: 2 !default;
$product-table-cell-quantity-grid-row--medium-down: 2 !default;
$product-table-cell-total-grid-column--medium-down: 2 !default;
$product-table-cell-total-grid-row--medium-down: 3 !default;
$product-table-cell-total-padding--medium-down: rem-calc(0 0 0 120) !default;
$product-table-cell-divider-padding: 0 !default;
$product-table-cell-total-text-align--large: $global-right !default;
$product-table-cell-total-padding--large: rem-calc(20 0 20 15) !default;
$product-table-divider-border: 8px solid color(global-background) !default;
$product-table-divider-border--large: 4px solid color(global-background) !default;
$product-table-promotion-text-align: $global-left !default;
$product-table-promotion-margin: rem-calc(10 0) !default;
$product-table-message-font: 500 #{rem-calc(14)} / 1.4 $font-primary !default;
$product-table-message-font--large: 500 #{rem-calc(16)} / 1.4 $font-primary !default;
$product-table-price-width: 100% !default;
// Actions
$product-table-actions-width: 100% !default;
$product-table-actions-margin--large: auto 0 0 !default;
$product-table-actions-padding--large: rem-calc(10 0 0) !default;
$product-table-actions-button-width: 100% !default;
$product-table-actions-button-margin: rem-calc(0 0 10) !default;
// Secondary table styles
$product-table-secondary-padding--medium-down: rem-calc(2 15 0) !default;
$product-table-secondary-body-padding--medium-down: rem-calc(10 0 0) !default;
$product-table-secondary-row-grid-template-columns--medium-down: rem-calc(90 80) auto !default;
$product-table-secondary-column-header-padding--large: rem-calc(20 25) !default;
$product-table-secondary-column-header-quantity-text-align--large: center !default;
$product-table-secondary-column-header-quantity-font-weight--large: null !default;
$product-table-secondary-column-header-price-text-align--large: $global-right !default;
$product-table-secondary-column-header-price-font-weight--large: null !default;
$product-table-secondary-cell-image-padding--large: rem-calc(20 15 20 25) !default;
$product-table-secondary-cell-details-padding--large: rem-calc(20 15 20 25) !default;
$product-table-secondary-cell-image-width--large: rem-calc(210) !default;
$product-table-secondary-cell-quantity-padding--medium-down: rem-calc(10 0 10 10) !default;
$product-table-secondary-cell-total-padding--medium-down: rem-calc(10 0) !default;
$product-table-secondary-cell-total-color--medium-down: null !default;
$product-table-secondary-cell-total-color--large: null !default;
$product-table-secondary-cell-total-width--large: rem-calc(210) !default;
$product-table-secondary-cell-total-padding--large: rem-calc(20 25 20 15) !default;
$product-table-secondary-cell-full-padding--large: rem-calc(20 25 0) !default;
$product-table-secondary-cell-total-text-align--large: $global-right !default;
$product-table-secondary-product-quantity-margin--medium-down: rem-calc(0 0 0 5) !default;
$product-table-secondary-product-divider-margin--medium-down: rem-calc(0 -15) !default;
$product-table-secondary-price-width--medium-down: 100% !default;
$product-table-secondary-cell-write-review-padding--medium-down: rem-calc(0) !default;
$product-table-secondary-cell-write-review-grid-column-end--medium-down: 4 !default;
$product-table-secondary-cell-write-review-grid-column-start--medium-down: 3 !default;
$product-table-secondary-cell-write-review-grid-row-start--medium-down: 3 !default;
$product-table-column-header-details-text-transform: null !default;
$product-table-column-header-details-text-transform--large: null !default;
$product-table-column-header-details-font: null !default;
$product-table-column-header-details-font--large: null !default;
$product-table-cell-divider-display: block !default;

// Reviews
$product-table-reviews-margin: rem-calc(20 0) !default;
$product-table-reviews-margin--large: rem-calc(15 0) !default;

// Link icon
$product-table-link-icon-text-decoration: none !default;
$product-table-link-icon-svg-margin: rem-calc(0 6 0 0) !default;
$product-table-link-icon-text-text-decoration: underline !default;
// Icon order
$product-table-link-icon-order-color: color(text-secondary) !default;
$product-table-link-icon-order-hover-color: color(primary) !default;
$product-table-link-icon-order-svg: 'burger' !default;
$product-table-link-icon-order-svg-size: rem-calc(12) !default;
$product-table-link-icon-order-is-mask: true !default;
// Icon review
$product-table-link-icon-review-color: color(primary) !default;
$product-table-link-icon-review-hover-color: color(text-secondary) !default;
$product-table-link-icon-review-svg: 'edit-2' !default;
$product-table-link-icon-review-svg-size: rem-calc(15) !default;
$product-table-link-icon-svg-display: inline-flex !default;
$product-table-link-icon-text-font: null !default;
$product-table-link-icon-review-is-mask: true !default;

@mixin lora-components-product-table {
    .c-product-table {
        border-collapse: collapse;
        width: 100%;

        @include breakpoint(medium down) {
            display: block;
        }

        .c-promotion {
            margin: $product-table-promotion-margin;
            padding: 0;
            text-align: $product-table-promotion-text-align;
        }

        &.m-no-image {
            .c-product-table__cell {
                padding: 0;
            }
        }
    }

    // Groups thead, tbody
    .c-product-table__header,
    .c-product-table__body {
        @include breakpoint(medium down) {
            display: block;
        }
    }

    // Columns headers
    .c-product-table__column-header {
        background: $product-table-column-header-background;
        color: $product-table-column-header-color;
        font: $product-table-column-header-font;
        padding: $product-table-column-header-padding;
        text-align: $global-left;
        text-transform: $product-table-column-header-text-transform;
        letter-spacing: $product-table-column-header-letter-spacing;

        @include breakpoint(medium down) {
            display: block;
            margin: $product-table-column-header-margin--medium-down;
        }

        &.m-details {
            text-transform: $product-table-column-header-details-text-transform;
            font: $product-table-column-header-details-font;

            @include breakpoint(large) {
                text-transform: $product-table-column-header-details-text-transform--large;
                font: $product-table-column-header-details-font--large;
            }
        }

        &.m-quantity,
        &.m-price {
            @include hide-for(medium down);
        }

        &.m-price {
            @include breakpoint(large) {
                text-align: $product-table-column-header-price-text-align--large;
            }
        }
    }

    // Rows
    .c-product-table__row {
        @include breakpoint(medium down) {
            display: block;
            position: relative;
        }

        @include breakpoint(large) {
            height: 100%; // Firefox issue with div height 100% inside a td, issue - NGLORA-2510
        }

        &.m-product {
            @include breakpoint(medium down) {
                border-bottom: $product-table-row-product-border-bottom--medium-down;
            }

            @include breakpoint(large) {
                border-top: $product-table-row-product-border-top--large;
            }
        }

        &.m-product,
        &.m-giftcert {
            @include breakpoint(medium down) {
                overflow: hidden;
                padding: $product-table-row-product-padding--medium-down;
                display: grid;
                grid-template-columns: $product-table-row-product-grid-template-columns--medium-down;
            }

            &:first-child {
                padding-top: 0;
                border-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        &.m-row-1 {
            @include breakpoint(medium down) {
                padding-bottom: 0;
            }
        }

        &.m-row-2 {
            @include breakpoint(medium down) {
                padding-top: 0;
            }
        }

        // Do not render first or consequtive dividers in case some line item is suppressed (e.g. by conflicting promotions)
        &.m-divider {
            &:first-child,
            & + & {
                display: none;
            }
        }

        // Reset the top padding for the first row and the bottom padding for the last row
        &:first-child {
            padding-top: 0;

            .c-product-table__cell {
                &:not(.m-quantity) {
                    padding-top: 0;
                }

                @include breakpoint(large) {
                    &.m-quantity { // stylelint-disable-line
                        padding-top: 0;
                    }
                }
            }
        }

        &:nth-last-child(2) {
            .c-product-table__cell {
                &:not(.m-quantity):not(.m-details):not(.m-upsell-recommendation) {
                    padding-bottom: 0;
                }

                @include breakpoint(large) {
                    &.m-quantity { // stylelint-disable-line
                        padding-bottom: 0;
                    }
                }
            }
        }

        &:last-child {
            padding-bottom: 0;

            .c-product-table__cell {
                &.m-actions {
                    padding-bottom: 0;
                }
            }
        }

        &.m-no-image {
            @include breakpoint(medium down) {
                grid-template-columns: $product-table-row-product-no-image-grid-template-columns--medium-down;
            }
            
            .c-product-table__cell.m-details {
                @include breakpoint(medium down) {
                    grid-column: $product-table-cell-details-no-image-grid-column--medium-down;
                }
            }

            .c-product-table__cell.m-details,
            .c-product-table__cell.m-actions {
                padding-#{$global-left}: 0;
            }

            .c-product-table__cell.m-quantity {
                @include breakpoint(medium down) {
                    padding-#{$global-left}: 0;
                    grid-column: $product-table-cell-quantity-no-image-grid-column--medium-down;
                }
            }

            .c-product-table__cell.m-actions {
                @include breakpoint(medium down) {
                    grid-column: $product-table-cell-actions-no-image-grid-column--medium-down;
                }
            }

            .c-product-table-allocation__qty {
                width: auto;
            }
        }
    }

    // Cells
    .c-product-table__cell {
        padding: $product-table-cell-padding;
        vertical-align: top;

        @include breakpoint(medium down) {
            display: block;
            padding: $product-table-cell-padding--medium-down; // see c-product-table__row.m-product mobile for additional padding
        }

        @include breakpoint(large) {
            height: 100%; // Firefox issue with div height 100% inside a td, issue - NGLORA-2510
        }

        &.m-image {
            max-width: $product-table-cell-image-width;
            padding: $product-table-cell-image-padding;
            width: $product-table-cell-image-width;

            @include breakpoint(medium down) {
                float: #{$global-left};
                grid-column: $product-table-cell-image-grid-column--medium-down;
                grid-row: $product-table-cell-image-grid-row--medium-down;
                padding: $product-table-cell-image-padding--medium-down;
                border-top: $product-table-cell-image-border-top--medium-down;
                border-bottom: $product-table-cell-image-border-bottom--medium-down;
            }
            @include breakpoint(large) {
                max-width: $product-table-cell-image-width--large;
                min-width: $product-table-cell-image-width--large;
                width: $product-table-cell-image-width--large;
            }

            .c-product-table-details__action {
                width: 100%;
            }
        }

        &.m-details {
            @include breakpoint(medium down) {
                grid-column: $product-table-cell-details-grid-column--medium-down;
                grid-row: $product-table-cell-details-grid-row--medium-down;
                border-top: $product-table-cell-details-border-top--medium-down;
                border-bottom: $product-table-cell-details-border-bottom--medium-down;
                padding: $product-table-cell-details-padding--medium-down;
            }

            @include breakpoint(large) {
                max-width: $product-table-cell-details-width--large;
                width: $product-table-cell-details-width--large;
                padding: $product-table-cell-details-padding--large;
            }
        }

        &.m-actions {
            padding: $product-table-cell-actions-padding;

            @include breakpoint(medium down) {
                grid-column: $product-table-cell-actions-grid-column--medium-down;
                grid-row: $product-table-cell-actions-grid-row--medium-down;
                padding: $product-table-cell-actions-padding--medium-down;
            }
        }

        &.m-quantity {
            font: $product-table-cell-quantity-font;
            margin: $product-table-cell-quantity-margin;
            color: $product-table-cell-quantity-color;

            @include breakpoint(medium down) {
                grid-column: $product-table-cell-quantity-grid-column--medium-down;
                grid-row: $product-table-cell-quantity-grid-row--medium-down;
                padding: $product-table-cell-quantity-padding--medium-down;
            }
            @include breakpoint(large) {
                max-width: $product-table-cell-quantity-width--large;
                width: $product-table-cell-quantity-width--large;
                font: $product-table-cell-quantity-font--large;
                padding: $product-table-cell-quantity-padding--large;
            }
        }

        &.m-total {
            @include breakpoint(medium down) {
                grid-column: $product-table-cell-total-grid-column--medium-down;
                grid-row: $product-table-cell-total-grid-row--medium-down;
                text-align: $global-right;
                padding: $product-table-cell-total-padding--medium-down;
            }
            @include breakpoint(large) {
                height: 1px;
                text-align: $product-table-cell-total-text-align--large;
                padding: $product-table-cell-total-padding--large;
                min-width: $product-table-cell-total-width--large;
                width: $product-table-cell-total-width--large;
            }

            .c-product-table__actions {
                @include hide-for(medium down);
            }
        }

        &.m-write-review {
            @include hide-for(large);
        }

        &.m-divider {
            padding: $product-table-cell-divider-padding;

            @include breakpoint(medium down) {
                display: $product-table-cell-divider-display;
            }
        }
    }

    .c-product-table__cell-inner {
        display: flex;

        @include breakpoint(medium down) {
            flex-flow: wrap;
            width: 100%;
        }
        @include breakpoint(large) {
            flex-flow: column;
            min-height: 100%;
        }
    }

    .c-product-table__actions {
        width: $product-table-actions-width;

        @include breakpoint(large) {
            margin: $product-table-actions-margin--large;
            padding: $product-table-actions-padding--large;
        }

        .c-button {
            margin: $product-table-actions-button-margin;
            width: $product-table-actions-button-width;

            @include breakpoint(small only) {
                min-width: auto;
            }
        }
    }

    .c-product-table__message {
        font: $product-table-message-font;

        @include breakpoint(large) {
            font: $product-table-message-font--large;
        }
    }

    .c-product-table__price {
        width: $product-table-price-width;
    }

    // Divider element
    .c-product-table__divider {
        border-bottom: $product-table-divider-border;
        display: block;

        @include breakpoint(large) {
            border-bottom: $product-table-divider-border--large;
        }
    }

    // Secondary table styles
    .c-product-table.m-secondary {
        @include breakpoint(medium down) {
            padding: $product-table-secondary-padding--medium-down;

            .c-product-table__row:not(.m-divider) {
                display: grid;
                grid-template-columns: $product-table-secondary-row-grid-template-columns--medium-down;
            }

            .c-product-table__column-header.m-details {
                grid-column: 1 / 4;
            }

            .c-product-table__cell {
                &.m-details {
                    grid-column: 2 / 4;
                    grid-row: 1;
                }

                &.m-quantity {
                    grid-column: 2;
                    grid-row: 2;
                    padding: $product-table-secondary-cell-quantity-padding--medium-down;
                }

                &.m-total {
                    grid-column: 3;
                    grid-row: 2;
                    padding: $product-table-secondary-cell-total-padding--medium-down;
                    color: $product-table-secondary-cell-total-color--medium-down;
                }

                &.m-write-review {
                    grid-column-end: $product-table-secondary-cell-write-review-grid-column-end--medium-down;
                    grid-column-start: $product-table-secondary-cell-write-review-grid-column-start--medium-down;
                    grid-row-start: $product-table-secondary-cell-write-review-grid-row-start--medium-down;
                    padding: $product-table-secondary-cell-write-review-padding--medium-down;
                }

                &.m-full {
                    grid-column: 1 / 4;
                    padding-inline: 0;
                }

                &.m-info {
                    grid-column: 2 / 4;
                }
            }

            .c-product-table__body {
                padding: $product-table-secondary-body-padding--medium-down;
            }

            .c-product-table__price {
                width: $product-table-secondary-price-width--medium-down;
            }

            .c-product-quantity {
                display: inline-block;
                margin: $product-table-secondary-product-quantity-margin--medium-down;
            }

            .c-product-quantity__value {
                vertical-align: baseline;
            }

            .c-product-table__divider {
                margin: $product-table-secondary-product-divider-margin--medium-down;
            }
        }
        @include breakpoint(large) {
            .c-product-table__column-header {
                padding: $product-table-secondary-column-header-padding--large;

                &.m-quantity {
                    text-align: $product-table-secondary-column-header-quantity-text-align--large;
                    font-weight: $product-table-secondary-column-header-quantity-font-weight--large;
                }

                &.m-price {
                    text-align: $product-table-secondary-column-header-price-text-align--large;
                    font-weight: $product-table-secondary-column-header-price-font-weight--large;
                }
            }

            .c-product-table__cell {
                &.m-image {
                    padding: $product-table-secondary-cell-image-padding--large;
                    width: $product-table-secondary-cell-image-width--large;
                }

                &.m-total {
                    padding: $product-table-secondary-cell-total-padding--large;
                    text-align: $product-table-secondary-cell-total-text-align--large;
                    min-width: $product-table-secondary-cell-total-width--large;
                    color: $product-table-secondary-cell-total-color--large;
                }

                &.m-full {
                    padding: $product-table-secondary-cell-full-padding--large;
                }
            }

            .c-product-table__quantity-label {
                display: none;
            }
        }

        .c-product-table__row:not(.m-divider) {
            /* stylelint-disable max-nesting-depth */
            &.m-no-image {
                @include breakpoint(medium down) {
                    grid-template-columns: $product-table-row-product-no-image-grid-template-columns--medium-down;
                }

                .c-product-table__cell.m-quantity {
                    @include breakpoint(medium down) {
                        padding-#{$global-left}: 0;
                    }
                }

                .c-product-table__cell.m-details {
                    @include breakpoint(large) {
                        padding: $product-table-secondary-cell-details-padding--large;
                    }
                }
            }
            /* stylelint-enable */
        }
    }

    .c-product-table__reviews {
        margin: $product-table-reviews-margin;

        @include breakpoint(large) {
            margin: $product-table-reviews-margin--large;
        }
    }

    .c-product-table__link-icon {
        display: inline-flex;
        align-items: center;
        text-decoration: $product-table-link-icon-text-decoration;
        cursor: pointer;

        &.m-order {
            @include lora-helper-icon-svg(
                $icon: $product-table-link-icon-order-svg,
                $size: $product-table-link-icon-order-svg-size,
                $color: $product-table-link-icon-order-color,
                $svg-selector: '.c-product-table__link-icon-svg',
                $is-mask: $product-table-link-icon-order-is-mask
            );

            color: $product-table-link-icon-order-color;

            &:hover,
            &:focus {
                color: $product-table-link-icon-order-hover-color;

                .c-product-table__link-icon-svg::before {
                    background-color: $product-table-link-icon-order-hover-color;
                }
            }
        }

        &.m-review {
            @include lora-helper-icon-svg(
                $icon: $product-table-link-icon-review-svg,
                $size: $product-table-link-icon-review-svg-size,
                $color: $product-table-link-icon-review-color,
                $svg-selector: '.c-product-table__link-icon-svg',
                $is-mask: $product-table-link-icon-review-is-mask
            );

            color: $product-table-link-icon-review-color;

            &:hover,
            &:focus {
                color: $product-table-link-icon-review-hover-color;

                .c-product-table__link-icon-svg::before {
                    background-color: $product-table-link-icon-review-hover-color;
                }
            }
        }
    }

    .c-product-table__link-icon-svg {
        display: $product-table-link-icon-svg-display;
        margin: $product-table-link-icon-svg-margin;
    }

    .c-product-table__link-icon-text {
        text-decoration: $product-table-link-icon-text-text-decoration;
        font: $product-table-link-icon-text-font;
    }
}
