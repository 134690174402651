$account-empty-page-text-align: center !default;
$account-empty-page-max-width--large: rem-calc(370) !default;
$account-empty-page-margin--large: 0 auto !default;
$account-empty-page-text-font-size: rem-calc(18) !default;
$account-empty-page-text-font-weight: null !default;
$account-empty-page-text-transform: null !default;
$account-empty-page-text-letter-spacing: null !default;
$account-empty-page-padding--medium-down: rem-calc(10 0 15) !default;
$account-empty-page-padding--large: null !default;
$account-empty-page-border--large: none !default;
$account-empty-page-actions-max-width--large: null !default;
$account-empty-page-margin--large: null !default;
$account-empty-page-description-font-size: rem-calc(14) !default;
$account-empty-page-text-color: null !default;
$account-empty-page-description-text-transform: null !default;
$account-empty-page-description-color: null !default;

@mixin lora-components-account-empty-page {
    .c-account__empty-page {
        text-align: $account-empty-page-text-align;

        @include breakpoint(medium down) {
            padding: $account-empty-page-padding--medium-down;
        }

        @include breakpoint(large) {
            border: $account-empty-page-border--large;
            padding: $account-empty-page-padding--large;
        }
    }

    .c-account__empty-page-text {
        font-size: $account-empty-page-text-font-size;
        font-weight: $account-empty-page-text-font-weight;
        color: $account-empty-page-text-color;
        text-transform: $account-empty-page-text-transform;
        letter-spacing: $account-empty-page-text-letter-spacing;
    }

    .c-account__empty-page-actions {
        @include breakpoint(large) {
            max-width: $account-empty-page-actions-max-width--large;
            margin: $account-empty-page-margin--large;
        }
    }

    .c-account__empty-page-description {
        font-size: $account-empty-page-description-font-size;
        text-transform: $account-empty-page-description-text-transform;
        color: $account-empty-page-description-color;
    }
}
