$chip-background: color(primary) !default;
$chip-border: null !default;
$chip-border-radius: rem-calc(15) !default;
$chip-box-shadow: null !default;
$chip-color: color(light) !default;
$chip-font: 300 #{rem-calc(14)} / 1 $font-primary !default;
$chip-font--large: null !default;
$chip-padding: rem-calc(8 15) !default;
$chip-padding--large: null !default;
$chip-margin: rem-calc(0 10 10 0) !default;
$chip-margin--large: null !default;
$chip-text-transform: null !default;
$chip-remove-icon-color: $chip-color !default;
$chip-remove-icon: 'cross' !default;
$chip-remove-icon-size: rem-calc(10) !default;
$chip-remove-icon-margin: rem-calc(0 0 0 10) !default;
$chip-remove-icon-vertical-align: middle !default;
$chip-remove-icon-usesvg: true !default;
$chip-hover-background: color(primary-active) !default;
$chip-hover-border: null !default;
$chip-hover-color: color(light) !default;
$chip-hover-border-color: null !default;

@mixin lora-components-chip {
    .c-chip {
        background: $chip-background;
        border: $chip-border;
        border-radius: $chip-border-radius;
        cursor: pointer;
        box-shadow: $chip-box-shadow;

        @include text-style(
            $font: (
                small: $chip-font,
                large: $chip-font--large
            ),
            $margin: (
                small: $chip-margin,
                large: $chip-margin--large
            ),
            $padding: (
                small: $chip-padding,
                large: $chip-padding--large
            ),
            $text-transform: (
                small: $chip-text-transform
            ),
            $color: (
                small: $chip-color
            )
        );

        &:hover,
        &:focus {
            background: $chip-hover-background;
            border: $chip-hover-border;
            color: $chip-hover-color;
            border-color: $chip-hover-border-color;
        }

        &.m-remove {
            &::after {
                @include svg-icon($chip-remove-icon, $chip-remove-icon-color, contain, center, no-repeat, $usesvg: $chip-remove-icon-usesvg);
            
                content: '';
                display: inline-block;
                vertical-align: $chip-remove-icon-vertical-align;
                width: $chip-remove-icon-size;
                height: $chip-remove-icon-size;
                margin: $chip-remove-icon-margin;
            }
        }
    }
}
